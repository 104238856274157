<div fxLayout="row wrap" fxLayoutAlign="center" style="padding:20px">
    <mat-card style="min-width: 500px;">
        <mat-card-header>
          <mat-card-title>Account Analysis</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="column" fxLayoutAlign="center center" style="width:100%">

            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="row">
                    <app-stat-chart *ngIf="totalAccountsSet !== undefined" [data]="totalAccountsSet" style="width: 300px;"></app-stat-chart>
                </div>
                <div fxLayout="row">
                    <app-stat-chart *ngIf="empCountAccountsSet !== undefined" [data]="empCountAccountsSet" style="width: 300px"></app-stat-chart>
                </div>

                <div fxLayout="row">
                    <app-stat-chart *ngIf="industryAccountsSet !== undefined" [data]="industryAccountsSet" style="width: 300px"></app-stat-chart>
                </div>

                <div fxLayout="row">
                    <app-stat-chart *ngIf="urlAccountsSet !== undefined" [data]="urlAccountsSet" style="width: 300px"></app-stat-chart>
                </div>
                <div *ngIf="startProgress === true && (totalAccountsSet === undefined || empCountAccountsSet === undefined || industryAccountsSet === undefined || urlAccountsSet === undefined)">
                    <mat-progress-spinner
                        color="primary"
                        mode="indeterminate"
                        value=50>
                    </mat-progress-spinner>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center" style="padding-top:40px;font-size: x-large;font-weight: bold;color: purple;" *ngIf="totalAccountsSet !== undefined">FlashCX.ai can fix this!!!</div>
          </div>

          <div *ngIf="errors != undefined && errors.length > 0" style="padding-top: 20px;">
            <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="danger-text">Incomplete Analysis</div>
                    </mat-panel-title>
                    <mat-panel-description>
                        Require adaptive search configuration update.
                    </mat-panel-description>
                  </mat-expansion-panel-header>
              
                  <mat-list>
                    <mat-list-item *ngFor="let err of errors"><mat-label>{{err}}</mat-label></mat-list-item>
                  </mat-list>
              
                </mat-expansion-panel>
              </mat-accordion>            
          </div>

        </mat-card-content>
        <mat-card-actions>
            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end ">
                <button mat-raised-button color="accent" (click)="analyze()">Start Analysis</button>
            </div>
        
        </mat-card-actions>
  </mat-card>
</div>