/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Sep 13 2020
 * 
 * Description: Manage settings
 * 
 */
import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core.service';
import { GlobalVariables } from '../model/global-variables.modal';
import { MitDataService } from '../data.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public core: CoreService,
              public dataSvc: MitDataService) { }

   override(){
    const dialogRef = this.core.showMessage(GlobalVariables.QUESTION, 'Are you sure?', 'This will override entire team customizations and this cannot be reverted.')
    dialogRef.afterClosed().subscribe( async(result) => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result === 'yes'){
        console.log("user said yes")

        //initiate the process to override all the user templates with admin user template
        await this.dataSvc.overrideOrgTemplates(this.core.user.userId)

        this.core.showMessage(GlobalVariables.SUCCESS, 'Update scheduled!', 'The process to update entire team custom template is in progress. No other action is required.')
      }else{
        console.log("user said no")
      }
    });
  }

  ngOnInit(): void {
  }

}
