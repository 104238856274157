/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 1 2022
 * 
 * Description: shared config and variables service
 * 
 */
import { Injectable } from '@angular/core';

import { UserModel } from './model/user.model'
import { PlanModel } from './model/plan.model'
import { ConfigModel } from './model/config.model';
import { AttributeDefModel } from './model/attribute-def.model'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from './message-box/message-box.component';
import { GlobalVariables } from './model/global-variables.modal';


@Injectable({
  providedIn: 'root'
})
export class CoreService {
  user: UserModel
  progressState:string   //determinate | indeterminate
  accountStartDate:Date
  plan:PlanModel
  config:ConfigModel
  loggedIn:boolean
  currentPage:string
  currentPageLabel:string
  AUTH_STRING = ''  
  FRESHDESK_AUTH_STRING = ''
  //LOGGING=true
  LOGGING=false

  /** for configure area 
   * These are for all attributes rather just for opty
  */
  optyAttributes:AttributeDefModel[] = []
  optyAvailableAttributes:AttributeDefModel[] = []
  
  constructor(public dialog: MatDialog) {
    this.loggedIn = false
    this.currentPage = 'home'
    this.currentPageLabel = 'CRM Configuration'
   }

   showMessage(type:string, msg:string, text:string){
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {
        type: type,
        msg: msg,
        text: text
      }
    });

    return dialogRef
  }


   log(msg:string, data:any){
     if(this.LOGGING === true)
      console.log(msg, data)
   }


   getObjectName(tempCrmName:string, cxObject:string){
    console.log("crm name: " + tempCrmName + " object:" + cxObject)
    if(tempCrmName === 'oracle_adaptive'){
      if(cxObject === 'opportunities')
        return 'Opportunity'
      
      if(cxObject === 'leads')
        return 'Lead'

      if(cxObject === 'contacts')
        return 'Contact'

      if(cxObject === 'accounts')
        return 'Account'
    }else if(tempCrmName === GlobalVariables.ORACLE){
      return cxObject
    }else if(tempCrmName === GlobalVariables.SALESFORCE){
      if(cxObject === 'accounts')
        return 'account'

      if(cxObject === 'contacts')
        return 'contact'

      if(cxObject === 'leads')
        return 'lead'

      if(cxObject === 'opportunities')
        return 'opportunity'      
    }else if(tempCrmName === GlobalVariables.FRESHSALES){
     
      if(cxObject === 'tickets')
        return 'tickets'    

      if(cxObject === 'opportunities')
        return 'deals'

      if(cxObject === 'accounts')
        return 'sales_accounts'
        
      return 'blaw'
    }    
  }

   launchMenu(item:string, itemLabel:string){
    this.currentPage = item
    this.currentPageLabel = itemLabel
  }


  pretifyAllNames(name:string){
    if(name === undefined)
      return  ''

    return name.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  pretifyDateRemaining(timestamp:number){
    return this.calcTimeRemain(timestamp)
  }

  calcTimeRemain(ts:number){
    var nowTs = (new Date()).getTime()
    var sec = (ts - nowTs)/1000

    if(sec > 59){
      var min = sec/60

      if(min > 59){
        var hour = min/60

        if(hour > 23){
           var days = hour/24 

         /*  if(days > 30){
               var mon = days/30

               if(mon > 11){
                   var year = mon/12
                   return year.toFixed(0) + " yrs"
               }
                   
               return mon.toFixed(0) + " months"
           }*/
           return days.toFixed(0) + " days"
        }

        return hour.toFixed(0) + " hours"
      }
      return min.toFixed(0) + " min"
    }else if(sec < 0)
      return "Expired"
    else
    return sec.toFixed(0) + " secs"
  }

}
