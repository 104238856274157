<div fxLayout="column" style="width: 100%">

    <div fxLayout="row" fxFlex=100 fxLayoutAlign="end ">
        <div style="padding-right:20px"><mat-form-field>
          <mat-select [(ngModel)]="licenseStatus" (selectionChange)="changeLicenseValue()">
            <mat-option value="active">
               Active
            </mat-option>
            <mat-option value="expired">
              Expired
           </mat-option>
           <mat-option value="all">
            All
         </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxFlex=100  style="padding:20px">
    
      <div fxLayout="column" style="padding-top:10px;width:100%" #TABLE>
        <table mat-table [dataSource]="dataSource">
            <!-- Company Column -->
            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef  class="tbl-th">Company </th>
              <td mat-cell *matCellDef="let element"><div (click)="updateEnterprise(element)" class="text-link"> {{element.company}} </div></td>
            </ng-container>
          
            <!-- Email Column -->
            <ng-container matColumnDef="adminEmail">
              <th mat-header-cell *matHeaderCellDef  class="tbl-th"> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.adminEmail}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="adminName">
              <th mat-header-cell *matHeaderCellDef  class="tbl-th"> User Name </th>
              <td mat-cell *matCellDef="let element"> {{element.adminName}} </td>
            </ng-container>
    
             <!-- Email Column -->
             <ng-container matColumnDef="licenseType">
                <th mat-header-cell *matHeaderCellDef  class="tbl-th"> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.licenseType}} </td>
              </ng-container>
    
                <!-- Email Column -->
             <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef  class="tbl-th"> Users </th>
                <td mat-cell *matCellDef="let element"> {{element.users}} </td>
              </ng-container>    
    
                <!-- Email Column -->
             <ng-container matColumnDef="expiryDate">
                <th mat-header-cell *matHeaderCellDef class="tbl-th"> Expiry </th>
                <td mat-cell *matCellDef="let element"> 
                    <div *ngIf="element.expiryDate === 'Expired'" style="color:red">{{element.expiryDate}}</div> 
                    <div *ngIf="element.expiryDate !== 'Expired'">{{element.expiryDate}}</div>
                </td>
              </ng-container>
    
    
                <!-- Domain Column -->
             <ng-container matColumnDef="domain">
              <th mat-header-cell *matHeaderCellDef class="tbl-th"> Domain </th>
              <td mat-cell *matCellDef="let element"> {{element.domain}} </td>
            </ng-container>
    
            <!-- Purchase Column -->
            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef class="tbl-th">Upgrade </th>
              <td mat-cell *matCellDef="let element"> <mat-icon color="primary" (click)="manageLicense(element)" style="cursor:pointer">add_shopping_cart</mat-icon> </td>
          </ng-container>
    
             <!-- Manage users Column -->
             <ng-container matColumnDef="key1">
              <th mat-header-cell *matHeaderCellDef class="tbl-th">Users </th>
              <td mat-cell *matCellDef="let element"> <mat-icon color="primary" (click)="manageUsers(element)"  style="cursor:pointer">people</mat-icon> </td>
          </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
      </div>    
     </div>
    
    <div fxLayout="row" fxFlex=100 fxLayoutAlign="end "  style="padding-right: 20px">
        <button mat-fab (click)="addEnterprise()">
            <mat-icon>add</mat-icon>
          </button>
    </div>
    </div>