<app-header></app-header>
<div fxLayout="column" fxLayoutAlign=" center" style="padding:20px">
    <mat-card style="width:80%">
        <mat-card-title style="margin-bottom: 0px">Your Current Plan</mat-card-title>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="center" style="height: 45px"><h1 style="color:#673DB6">NINJA</h1></div>
            <div fxLayout="row" fxLayoutAlign="center"><h3>0 team members invited</h3></div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign=" center" style="padding-top:10px">
                <div fxLayout="row" fxFlex="42%" style="padding-left:20px">
                    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" style="margin-left:70px">
                    30 days left in your trial. It will switch to Individual plan after that. 
                    </div>
                    <div fxLayout="row" style="padding-left:10px">
                    <mat-chip-list>
                            <mat-chip color="accent" style="width:95px;;color:#382903" selected>{{getDaysLeft()}} days left</mat-chip>
                    </mat-chip-list>
                    </div>
                </div>
                <div fxLayout="row" fxFlex="16%" fxLayoutAlign="center center">
                <h1>$199</h1>
                </div>
                <div fxLayout="row" fxFlex="42%" fxLayoutAlign=" center" style="padding-right:20px">
                        <div fxLayout="row">
                            <mat-chip-list>
                                    <mat-chip color="primary"  style="width:100px;cursor: pointer;" (click)="upradePlan('ninja')" selected>Buy this plan</mat-chip>
                            </mat-chip-list>
                        </div>
                        <div fxLayout="row" style="padding-left:20px">
                        Buy this plan if you want a fully featured product. Or you can choose another plan from below
                        </div>
                 </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div fxLayout="row" fxLayoutAlign="center"><h1>Upgrade your plan</h1></div>
    <div fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button [color]="monthlyPlanColor" (click)="choosePlan('monthly')">Monthly</button>
        <button mat-raised-button [color]="annualPlanColor"  (click)="choosePlan('annual')">Annualy</button>
    </div>
    <div fxLayout="row" style="padding-top:20px" fxLayoutAlign="center" fxLayoutGap="20px">
        <div>
        <mat-card style="border-top:3px solid orange">
                <mat-card-title style="margin-bottom: 0px">Individual</mat-card-title>
                <mat-card-content>
                    <div fxLayout="column">
                        <div fxLayoutAlign="center">
                           <h1>$0</h1>
                        </div>
                        <button mat-raised-button color="primary">Activate</button>
                        <h2>Features</h2>

                        <mat-list dense>
                                <mat-divider></mat-divider>
                                <mat-list-item>Always free </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item>Up to 5 slack commands</mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item></mat-list-item>
                        </mat-list>
                    </div>
                </mat-card-content>
        </mat-card>
        </div>
        <div>
        <mat-card  style="border-top:3px solid orange">
                <mat-card-title style="margin-bottom: 0px">Team</mat-card-title>
                <mat-card-content>
                        <div fxLayout="column">
                            <div fxLayoutAlign="center">
                               <h1>{{pricing.team[plan]}}</h1>
                            </div>
                            <button mat-raised-button color="primary" (click)="upradePlan('team')">Upgrade</button>
                            <h2>Features</h2>
    
                            <mat-list dense>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Up to 7 members </mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>All slack commands</mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Ability to customize slack response</mat-list-item>
                                    <mat-divider></mat-divider>
                            </mat-list>
                        </div>
                    </mat-card-content>
        </mat-card>
    </div>
    <div>
        <mat-card  style="border-top:3px solid orange">
                <mat-card-title style="margin-bottom: 0px">Pro</mat-card-title>
                <mat-card-content>
                        <div fxLayout="column">
                            <div fxLayoutAlign="center">
                               <h1>{{pricing.pro[plan]}}</h1>
                            </div>
                            <button mat-raised-button color="primary" (click)="upradePlan('pro')">Upgrade</button>
                            <h2>Features</h2>
    
                            <mat-list dense>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Up to 20 members </mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>All slack commands</mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Ability to customize slack response</mat-list-item>
                                    <mat-divider></mat-divider>
                            </mat-list>
                        </div>
                    </mat-card-content>
        </mat-card>
    </div>
    <div>
        <mat-card  style="border-top:3px solid #673DB6">
                <mat-card-title style="margin-bottom: 0px">Ninja</mat-card-title>
                <mat-card-content>
                        <div fxLayout="column">
                            <div fxLayoutAlign="center">
                               <h1>{{pricing.ninja[plan]}}</h1>
                            </div>
                            <button mat-raised-button color="primary" (click)="upradePlan('ninja')">Upgrade</button>
                            <h2>Features</h2>
    
                            <mat-list dense>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Up to 50 members </mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>All slack commands</mat-list-item>
                                    <mat-divider></mat-divider>
                                    <mat-list-item>Ability to customize slack response</mat-list-item>
                                    <mat-divider></mat-divider>
                            </mat-list>
                        </div>
                    </mat-card-content>
        </mat-card>
    </div>
    </div>
</div>