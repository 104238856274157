<div fxLayout="column" style="padding:20px;width: 500px;">
    <div fxLayout="row" fxLayoutAlign="center ">
        <img [src]="imageLink" width="200">
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="cbh-dialog-title" style="padding-top: 20px;">{{data.msg}}</div>
    <div fxLayout="row" fxLayoutAlign="center"  class="cbh-dialog-text" style="padding-top: 20px;">{{data.text}}</div>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" style="padding-top: 30px;">
        <button mat-raised-button *ngIf="data.type !== 'question'" [ngClass]="data.type == 'error' ? 'cbh-error-btn' : data.type === 'warning' ? 'cbh-warning-btn' : 'cbh-success-btn'" mat-dialog-close>Ok</button>
        <button mat-raised-button *ngIf="data.type === 'question'" mat-dialog-close>Cancel</button>
        <button mat-raised-button *ngIf="data.type === 'question'" [ngClass]="data.type == 'question' ? 'cbh-warning-btn' : 'cbh-warning-btn'" (click)="sayYes()">Yes</button>
    </div>
</div>