<div fxLayout="column" fxLayoutAlign="start" style="padding:20px;width: 100%;">
    <div fxLayout="row" fxLayoutAlign="center" class="cbh-dialog-title">Create Workflow</div>
    <div fxLayout="row" fxLayoutAlign="center" style="padding-top: 10px;" fxFlex>
        <div fxLayout="column" fxLayoutAlign=" center">
            <mat-card>
                <mat-card-header>
                  <mat-card-title>Describe workflow</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput  placeholder="Name your workflow"  [formControl]="wfName"  required>
                    </mat-form-field>
    
                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput  placeholder="Describe your workflow"  [formControl]="description"  required></textarea>
                    </mat-form-field>
    
                    <mat-form-field style="width: 100%" floatLabel='never' appearance="outline">
                        <mat-label>Select Object</mat-label>
                        <mat-select (selectionChange)="cxObjectChanged($event.value)">
                          <mat-option value="accounts">Account</mat-option>
                          <mat-option *ngIf="tempCrmName !== 'freshsales'" value="contacts">Contact</mat-option>
                          <mat-option *ngIf="tempCrmName !== 'freshsales'"  value="leads">Lead</mat-option>
                          <mat-option *ngIf="tempCrmName !== 'freshsales'"  value="opportunities">Opportunity</mat-option>
                          <mat-option *ngIf="tempCrmName !== 'freshsales'"  value="tickets">Ticket (SR)</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field floatLabel='never' appearance="outline" style="width: 50%">
                        <mat-label>Notification Channel</mat-label>
                        <mat-select [formControl]="notiChannel">
                          <mat-option value="teams">Teams</mat-option>
                          <mat-option value="slack">Slack</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field style="padding-left: 10px" appearance="outline" *ngIf="notiChannel.value === 'slack'">
                        <mat-label>Channel id</mat-label>
                        <input matInput  placeholder="Slack channel id"  [formControl]="channelId"  required>
                    </mat-form-field>
                </mat-card-content>
           </mat-card>
        </div>
    </div>

           

           <ng-container *ngIf="cxObject !== undefined && objAttributes.length > 0">
            <div fxLayout="row" fxLayoutAlign="start " style="padding-top: 10px;">
                <button mat-raised-button (click)="addBlankRow()">Add condition</button>
            </div>

              <ng-container *ngFor="let row of queryRows">
                <app-wf-query-row [objAttributes]= "objAttributes" [num]= "row.num" [qrow]= "row" (qRowEvent)="queryRowAdded($event)" (qRowDeleteEvent)="queryRowDeleted($event)"></app-wf-query-row>
              </ng-container>
           </ng-container>

           <div fxLayout="row" fxLayoutAlign="center" style="padding-top:20px">
                <button mat-raised-button class="cbh-wizard-button" color="primary" (click)="cancel()">Cancel</button>
                &nbsp;&nbsp;&nbsp;&nbsp;<button  class="cbh-wizard-button" mat-raised-button color="primary" (click)="validateAndSave()">Validate & Save</button>

            </div>   
</div>