import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  title:string
  text:string
  reason:string
  showReasonLabel:boolean
  showErrorLabel:boolean
  iconType:string

  constructor(public dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
        //console.log(data.reason)
        this.title = data.title
        this.text = data.message
        this.reason = data.reason
        this.showReasonLabel = data.showReasonLabel
        this.iconType = data.iconType
        this.showErrorLabel = data.showErrorLabel
     }

  ngOnInit() {
  }

}
