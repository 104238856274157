<div fxLayout="column" style="background-color: #3d44a4;height: 100vh;background: url('/assets/images/bg.png');background-size: cover">
<app-header></app-header>
<div *ngIf="core.loggedIn == false" fxLayout="column" fxFlex="100%" fxLayoutAlign="center " style="height: 100%;background-image: url('/assets/images/bg.png');background-position:center;background-size:cover;background-repeat: no-repeat;" >
    <div fxLayout="row" fxLayoutAlign="center " fxFlex=100 style="padding-top:100px">
    <firebase-ui
    (signInSuccessWithAuthResult)="successCallback($event)"
             (signInFailure)="errorCallback($event)">
    </firebase-ui> 
    </div>

    <div fxLayout="row" fxLayoutAlign="center " style="padding-top:20px;padding-bottom:20px;color:purple;background-color: lavender;">
        If you are here first time then use your work email above to create your FlashCX.ai admin account. Once you are done, try the slack or teams flow again.
    </div>
  </div>
<div *ngIf="core.loggedIn === true" fxLayout="column" fxFlex="100%">

    <div fxLayout="row" fxFlex="100%">
        <div *ngIf="showMenu == true" fxLayout="column" style="width:400px;padding:10px;background-color: white;">
            <mat-list style="padding-bottom: 100px">
    
              <ng-container>
                    <mat-list-item>
                        <mat-icon mat-list-icon [ngClass]="core.currentPage == 'home' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'" >home</mat-icon>
                      <h4  [ngClass]="core.currentPage == 'home' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('home', 'Home')">Home</h4>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-icon mat-list-icon [ngClass]="core.currentPage == 'customize' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'">toys</mat-icon>
                      <h4  [ngClass]="core.currentPage == 'customize' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('customize', 'Configure Objects')">Customize</h4>
                    </mat-list-item>

                    <ng-container *ngIf="core.user.role === 'admin' || core.user.role === 'enterpriseAdmin' || core.user.role === 'superUser'">
                          <mat-list-item>
                            <mat-icon mat-list-icon [ngClass]="core.currentPage == 'enrich' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'">flash_on</mat-icon>
                            <h4  [ngClass]="core.currentPage == 'enrich' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('enrich', 'Account Enrichment')">Account Enrichment</h4>
                          </mat-list-item>

                            <mat-list-item>
                              <mat-icon mat-list-icon [ngClass]="core.currentPage == 'settings' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'">settings</mat-icon>
                              <h4  [ngClass]="core.currentPage == 'settings' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('settings', 'Settings')">Settings</h4>
                            </mat-list-item>

                            <mat-list-item>
                              <mat-icon mat-list-icon [ngClass]="core.currentPage == 'workflows' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'">account_tree</mat-icon>
                              <h4  [ngClass]="core.currentPage == 'workflows' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('workflows', 'Workflows')">Workflows</h4>
                            </mat-list-item>
                    </ng-container>

                    <ng-container *ngIf="core.user.role === 'superUser'">
                      <mat-list-item>
                        <mat-icon mat-list-icon [ngClass]="core.currentPage == 'enrich' ? 'sb-selected sg-link' : 'sb-greyed-out sg-link'">business</mat-icon>
                        <h4  [ngClass]="core.currentPage == 'enrich' ? 'sb-selected sg-link' : 'sg-text-color sg-link'" mat-line (click)="launchMenu('createEnterprise', 'Manage Enterprise')">Enterprises</h4>
                      </mat-list-item>
                   </ng-container>


    
            </ng-container>
                    
            </mat-list>
            <button mat-raised-button color="primary" (click)="logout()">Logout</button>
        </div>
        <div  fxLayout="column" fxLayoutAlign="start  " class="sg-text-color" style="padding:10px;width:100%">
            
            <app-crm-config  *ngIf="core.currentPage == 'home'" style="padding:20px"></app-crm-config>
            <app-customize *ngIf="core.currentPage == 'customize'"></app-customize>
            <app-enrichment *ngIf="core.currentPage == 'enrich'"></app-enrichment>
            <app-settings *ngIf="core.currentPage == 'settings'"></app-settings>
            <app-workflows *ngIf="core.currentPage == 'workflows'"></app-workflows>
            <app-manage-enterprise *ngIf="core.currentPage == 'createEnterprise'" style="padding:20px;width: 100%"></app-manage-enterprise>
        </div>
    
        </div>
</div>