/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Sep 13 2020
 * 
 * Description: Stores user profile
 * 
 */
export class UserModel{
    private _name:string
    private _email:string
    private _userId:string    //this is the firebase auth id
    private _teamId:string
    private _avatar:string
    private _teamName:string
    private _role:string
    private _domain:string

    constructor(name:string, email:string, userId:string, avatar:string, role:string, domain:string){
        this._name = name
        this._email = email
        this._userId = userId
        this._avatar = avatar
        this._role = role
        this._domain = domain
    }

    get name():string{
        return this._name
    }

    set name(val:string){
        this._name = val
    }

    get email():string{
        return this._email
    }

    set email(val:string){
        this._email = val
    }

    get userId():string{
        return this._userId
    }

    set userId(val:string){
        this._userId = val
    }

    get teamId():string{
        return this._teamId
    }

    set teamId(val:string){
        this._teamId = val
    }

    get avatar():string{
        return this._avatar
    }

    set avatar(val:string){
        this._avatar = val
    }

    get teamName():string{
        return this._teamName
    }

    get role():string{
        return this._role
    }

    get domain():string{
        return this._domain
    }
}