import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<MessageBoxComponent>,) { }

  //type = 'error'
  imageLink:string

  sayYes(){
    this.dialogRef.close('yes');
  }

  ngOnInit(): void {
    if(this.data.type === 'warning' || this.data.type === 'question')
      this.imageLink = 'assets/images/alert.png'
    else if(this.data.type === 'error')
      this.imageLink = 'assets/images/error.png'
    else 
      this.imageLink = 'assets/images/success.png'
  }


}
