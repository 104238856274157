<div fxLayout="column" style="width:100%;border-top: 3px solid #673DB6;padding-left:10px;padding-top:5px">
    <div fxLayout="row" fxLayoutAlign=" center">
        <div style="padding-top:15px"><h2 mat-dialog-title>&nbsp;Attribute Properties</h2></div>
    </div>
  
  <mat-dialog-content>
    <div fxLayout="column" style="padding-left:20px;padding-right: 20px">
        <mat-form-field appearance="outline">
            <mat-label>Display Label (read only)</mat-label>
            <input matInput placeholder="Display Label (read only)"  [formControl]="title"  floatLabel='always' readonly disabled>
        </mat-form-field>

        <mat-form-field  appearance="outline" style="width: 300px;">
          <mat-label>Search attribute to add</mat-label>

        <input type="text"
          placeholder="Select attribute"
          matInput
          [formControl]="avlAttrField"
          [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let attr of filteredOptions | async" value="{{attr.name}}">
                    {{attr.name}}
                </mat-option>
            </mat-autocomplete> 
        </mat-form-field>

        <!--mat-form-field floatLabel='never' appearance="outline">
            <mat-label>Attribute Name</mat-label>
            <mat-select [formControl]="name" required value="name">
              <mat-option *ngFor="let attr of allAttributes" [value]="attr.name">{{attr.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error>

          </mat-form-field -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions >
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center" style="padding-top:10px">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-actions>
  </div>
  