export class AttributeDefModel{
     name:string
     title:string
     link:boolean
     inFactList:boolean
     lov:any
     type:string
     removable:boolean

    constructor(name:string, title:string, type:string, link:boolean, inFactList:boolean, lov:any){
        this.name = name
        this.title = title
    //    this.link = link   No usage at this point to add link to newly added attributes
        this.inFactList = inFactList
        this.type = type

        if(lov == undefined)
            lov = {}
        this.lov = lov
    }

  /*  get name():string{
        return this._name
    }

    get title():string{
        return this._title
    }

    get link():boolean{
        return this._link
    }

    get short():boolean{
        return this._short
    }

    get lov():any{
        return this._lov
    }*/

}