/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 18 2021
 * 
 * Description: This code manages the enterprise license upgrades
 * 
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from '../core.service';
import { MitDataService } from '../data.service';
import { MessageComponent } from '../message/message.component';
import { GlobalVariables } from '../model/global-variables.modal';

@Component({
  selector: 'app-update-enterprise-license',
  templateUrl: './update-enterprise-license.component.html',
  styleUrls: ['./update-enterprise-license.component.css']
})
export class UpdateEnterpriseLicenseComponent implements OnInit {

  licenseType = new FormControl('');
  startDate = new FormControl('')
  endDate = new FormControl('')
  users = new FormControl('')


  key:string

  constructor(public dataSvc: MitDataService,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackBar: MatSnackBar,
    public core:CoreService) {
    this.key = data.ent.key
   }

   async raiseMessage(msg:string){
    this._snackBar.openFromComponent(MessageComponent, {
      duration: 3000,
      data: msg,
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  async updateLicense(){
    console.log(this.licenseType.value + ":" + this.startDate.value + " :" + this.key)
    if(this.key === undefined){
      alert('breaking changes fix these')
      return
    }
    await this.dataSvc.updateLicense(this.licenseType.value,(new Date(this.startDate.value)).getTime(), (new Date(this.endDate.value)).getTime(), this.users.value, this.key)

    this.core.showMessage(GlobalVariables.SUCCESS, 'Complete!', 'Enterprise license upgraded')

  }

  ngOnInit() {
  }

}
