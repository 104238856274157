/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Feb 24 2021
 * 
 * Description: Module file to manage global imports and config
 * 
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgModule, PLATFORM_ID, NgZone } from '@angular/core';
import { routing } from './app.routing';

import { MomentModule } from 'ngx-moment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions'

import { environment } from '../environments/environment';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort'

import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { CoreService } from './core.service';
import { LoginComponent } from './login/login.component';
import { MessageComponent } from './message/message.component';
import { BillingComponent } from './billing/billing.component';
import { HeaderComponent } from './header/header.component';
import { CustomizeComponent } from './customize/customize.component';
import { AddAttributeComponent } from './add-attribute/add-attribute.component'

import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
import { CrmConfigComponent } from './crm-config/crm-config.component';
import { EnrichmentComponent } from './enrichment/enrichment.component';

import { ChartsModule } from 'ng2-charts';
import { StatChartComponent } from './stat-chart/stat-chart.component';
import { SettingsComponent } from './settings/settings.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { ManageEnterpriseComponent } from './manage-enterprise/manage-enterprise.component';
import { CreateEnterpriseComponent } from './create-enterprise/create-enterprise.component';

import {
  AngularFireDatabaseProject1,
  AngularFireDatabaseSB
} from './firebase.factory';
import { WorkflowsComponent } from './workflows/workflows.component';
import { UpdateEnterpriseLicenseComponent } from './update-enterprise-license/update-enterprise-license.component';
import { CreateWorkflowComponent } from './create-workflow/create-workflow.component';
import { WfQueryRowComponent } from './wf-query-row/wf-query-row.component';
import { ReplaceAttributeComponent } from './replace-attribute/replace-attribute.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      scopes: [
        'public_profile',
        'email'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    }
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    MessageComponent,
    BillingComponent,
    HeaderComponent,
    CustomizeComponent,
    AddAttributeComponent,
    CrmConfigComponent,
    EnrichmentComponent,
    StatChartComponent,
    SettingsComponent,
    MessageBoxComponent,
    ManageEnterpriseComponent,
    CreateEnterpriseComponent,
    WorkflowsComponent,
    UpdateEnterpriseLicenseComponent,
    CreateWorkflowComponent,
    WfQueryRowComponent,
    ReplaceAttributeComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChartsModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MomentModule,
    ReactiveFormsModule,
    routing
  ],
  entryComponents:[
    MessageComponent,
    AddAttributeComponent,
    UpdateEnterpriseLicenseComponent
  ],
  providers: [
    CoreService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: 'env',
      useValue: environment
    }, {
      provide: 'firebase',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireDatabaseProject1
    },
    {
      provide: 'sbFirebase',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireDatabaseSB
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
