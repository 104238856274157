/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Apr 9 2021
 * 
 * Description: This dialog adds ability to replace underlying attribute to another attribute
 * 
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreService } from '../core.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-replace-attribute',
  templateUrl: './replace-attribute.component.html',
  styleUrls: ['./replace-attribute.component.css']
})
export class ReplaceAttributeComponent implements OnInit {
  title = new FormControl('', [Validators.required]);
 // name = new FormControl('', [Validators.required]);
  allAttributes = []
  filteredOptions: Observable<any>;
  avlAttrField = new FormControl('')


  constructor(public dialogRef: MatDialogRef<ReplaceAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public core: CoreService) {
      this.title.setValue(data.title)
   //   this.name.setValue(data.name)

      this.avlAttrField.setValue(data.name)

      this.filteredOptions = this.avlAttrField.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
     }

  
     private _filter(value: any): any[] {
      const filterValue = value.toLowerCase();
      return this.core.optyAvailableAttributes.filter( (option:any) => option.name.toLowerCase().includes(filterValue));
    }


  /**
  * Shows required field validations
  */
 getErrorMessage() {
  return this.title.hasError('required') ? 'You must enter a value' : '';
}


/**
 * this method will add the attribute to the available list
 */
 save(){

  if(this.avlAttrField.value.trim() === '')
    return

  console.log("returning ", this.avlAttrField.value)

  this.dialogRef.close({
    "title" : this.title.value,
    "name": this.avlAttrField.value
  })
}


  ngOnInit(): void {
    //need all available as well as already added attributes
    this.allAttributes = this.core.optyAvailableAttributes.concat(this.core.optyAttributes)
  }

}
