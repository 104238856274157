/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jun 13 2023
 * 
 * Description: Let save crm credentials 
 * 
 */
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { OecService } from '../oec.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MitDataService } from '../data.service';
import { ConfigModel } from '../model/config.model';
import { GlobalVariables } from '../model/global-variables.modal';
import { AngularFireFunctions } from '@angular/fire/functions'

@Component({
  selector: 'app-crm-config',
  templateUrl: './crm-config.component.html',
  styleUrls: ['./crm-config.component.scss']
})
export class CrmConfigComponent implements OnInit {
  basicColor:string
  ssoColor:string
  secType:string

  endpoint = new FormControl('', [Validators.required]);
  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  crmName = new FormControl('', [Validators.required]);
  apiKey = new FormControl('', [Validators.required]);

  //Freshworks is a speical case where customer might be using FreshSales and FreshDesk both. Freshdesk is for ticket management and FreshSales for rest of the CRM
  //This is completely optional setup
  freshDeskApiKey = new FormControl('');
  freshDeskEndpoint = new FormControl('');

  isAdaptive:boolean
  connected:boolean  //to indicated if sfdc oauth succeed
  isSso:boolean
  ssoConnected:boolean

  constructor(public core:CoreService,
              public oec: OecService,
              public snackBar: MatSnackBar,
              public dataSvc: MitDataService,
              private fns: AngularFireFunctions) {
    this.basicColor = "primary"
    this.ssoColor = ""
    this.secType = "basic"
    this.connected = false
    this.isAdaptive = true
    this.isSso = false
    this.ssoConnected = true
   }

   async loginHubspot(){
    window.open('https://app.hubspot.com/oauth/authorize?client_id=b54011b0-dae7-4ec1-a898-c08a7f7921cb&redirect_uri=https://us-central1-salesbot-slack.cloudfunctions.net/oauthHubspot&scope=tickets%20crm.objects.contacts.read&state=' + this.core.user.email)
   }

   async loginSFDC(){
    var loginFunction = this.fns.httpsCallable('loginSfdc');
    loginFunction({}).subscribe(res => {
      console.log("differ:", res)
      window.open(res, 'loginWindow', 'height=500,width=500');
      window.document.addEventListener("oauthCallback", (e:any) => { 
            //console.log("callback e is:", e)
            this.processOAuthCallback(e.detail);
      });
    });
   }

  processOAuthCallback(authCodeUrl) {
    console.log("start of client Proccess OAuth Callback");
    console.log(authCodeUrl);
  
    var res = authCodeUrl.split("code=");
    var code = res[1];
    code = code.replaceAll("%3D", "=");
  
    console.log(code);
  
    var processOAuthCallbackFunction = this.fns.httpsCallable('processOAuthCallbackSfdc');
    processOAuthCallbackFunction({code: code}).subscribe(async(res) => {
        //console.log("Final results:::::", res)
        this.connected = true
        const payload = {
          "accessToken": res.accessToken,
          "refreshToken": res.refreshToken,
          "endpoint": res.instanceUrl,
          "crmName": GlobalVariables.SALESFORCE
        }

        if(res.sfdcUid !== undefined)
          payload["sfdcUid"] = res.sfdcUid

        await this.dataSvc.saveCXConfig(payload, this.core.user.userId)  

        this.snackBar.open("Connection successful",'', {
          duration: 1500,
        });
    })
    
  }


   showSaveDisabled(){
    if(this.username !== undefined && this.username.value !== undefined && this.username.value.indexOf('\\') > -1)
      return false

    return true
   }

   /**
    * to deal with ArcBest case where login is a microsoft base ADCS provider for sso
    */
   microsoftIDUser(){
     if(this.username !== undefined && this.username.value !== undefined && this.username.value.indexOf('\\') > -1)
      return true

      return false
   }


   validationRules(){
    if(this.endpoint.value == undefined || this.endpoint.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Endpoint cannot be empty')
      return false;
    }

    if(this.endpoint.value.startsWith('http') === false){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Endpoint must begin with http')
      return false;
    }

    if(this.username.value == undefined || this.username.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Username cannot be empty')
      return false;
    }

    if(this.password.value == undefined || this.password.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Password cannot be empty')
      return false;
    }

    return true
   }

   /**
    * This method saves FreshSales credentials without validation.
    */
   async saveFreshSales(){
    this.connected = false
   if(this.endpoint.value == undefined || this.endpoint.value.trim() == ''){
     this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'FreshSales url cannot be empty')
     return false;
   }

   if(this.apiKey.value == undefined || this.apiKey.value.trim() == ''){
     this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'FreshSales API key cannot be empty')
     return false;
   }

    //also validate FreshDesk if setup is enabled
    if((this.freshDeskEndpoint.value.trim() !== '' && this.freshDeskApiKey.value.trim() === '') 
       || (this.freshDeskEndpoint.value.trim() === '' && this.freshDeskApiKey.value.trim() !== '')){
         this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'For Freshdesk both End point and Api key are required')
     return false;
   }

   this.core.progressState = 'indeterminate'
   const payload = {
     "apiKey": this.apiKey.value.trim(),
     "url": this.endpoint.value.trim(),
     "freshDeskApiKey": this.freshDeskApiKey.value.trim(),
     "freshDeskUrl": this.freshDeskEndpoint.value.trim()
   }
   
   try{
      
       //save to db
       const payloadCrm =  { 
         apiKey: this.apiKey.value.trim(),
         endpoint: this.endpoint.value.trim(),
         freshDeskApiKey: this.freshDeskApiKey.value.trim(),
         freshDeskEndPoint: this.freshDeskEndpoint.value.trim(),
         cxAuthType: GlobalVariables.TOKEN,
         crmName: GlobalVariables.FRESHSALES
       }

       try{
             await this.dataSvc.saveCXConfig(payloadCrm, this.core.user.userId)  
             this.core.progressState = 'determinate'
             this.connected = true
         }catch(err){
           console.error(err)
           this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', 'Failed to save settings.')
       }

   }catch(err) {
     this.connected = false
     if(err.status == '401')
         this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', 'Invalid host or API. Authentication Failure.')
     else
       this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', err.status + ':' + err.statusText)
    }

    this.core.progressState = 'determinate'

  }


   /**
    * This method validates FreshSales login
    */
   async validateFreshSales(){
     this.connected = false
    if(this.endpoint.value == undefined || this.endpoint.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'FreshSales url cannot be empty')
      return false;
    }

    if(this.apiKey.value == undefined || this.apiKey.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'FreshSales API key cannot be empty')
      return false;
    }

    console.log("this.endpoint.value.toLowerCase().indexOf('freshsales'):", this.endpoint.value.toLowerCase().indexOf('freshsales'))

    if(this.endpoint.value !== undefined && this.endpoint.value.toLowerCase().indexOf('freshsales') < 0 && this.endpoint.value.toLowerCase().indexOf('freshworks.com') < 0){
      this.core.showMessage(GlobalVariables.ERROR, 'Invalid Freshsales Url', 'Valid format is: https://<your company>.freshsales.io or https://<your company>.freshworks.com')
      return false;
    }

     //also validate FreshDesk if setup is enabled
     if((this.freshDeskEndpoint.value.trim() !== '' && this.freshDeskApiKey.value.trim() === '') 
        || (this.freshDeskEndpoint.value.trim() === '' && this.freshDeskApiKey.value.trim() !== '')){
          this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'For Freshdesk both End point and Api key are required')
      return false;
    }

    if(this.endpoint.value.trim().length > 0 && this.endpoint.value.trim().startsWith('https://') === false){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Freshworks Url must start with https://')
      return false;
    }

    if(this.freshDeskEndpoint.value.trim().length > 0 && this.freshDeskEndpoint.value.trim().startsWith('https://') === false){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Freshdesk Url must start with https://')
      return false;
    }

    if(this.freshDeskEndpoint.value.trim().length > 0 && this.freshDeskEndpoint.value.toLowerCase().indexOf('freshdesk') < 0){
      this.core.showMessage(GlobalVariables.ERROR, 'Invalid Freshdesk Url', 'Valid format is: https://<your company>.freshdesk.com')
      return false;
    }

    this.core.progressState = 'indeterminate'
    const payload = {
      "apiKey": this.apiKey.value.trim(),
      "url": this.endpoint.value.trim(),
      "freshDeskApiKey": this.freshDeskApiKey.value.trim(),
      "freshDeskUrl": this.freshDeskEndpoint.value.trim()
    }

    let resp;
    
    try{
       resp = await this.oec.validateFreshSalesConnection(payload)
       //console.log("Resp is:" + JSON.stringify(resp))

      //also validate freshDesk if that is specified too
      if(this.freshDeskEndpoint.value.trim() !== ''){
        resp = await this.oec.validateFreshDeskConnection(payload)
      }

        //save to db
        const payloadCrm =  { 
          apiKey: this.apiKey.value.trim(),
          endpoint: this.endpoint.value.trim(),
          freshDeskApiKey: this.freshDeskApiKey.value.trim(),
          freshDeskEndPoint: this.freshDeskEndpoint.value.trim(),
          cxAuthType: GlobalVariables.TOKEN,
          crmName: GlobalVariables.FRESHSALES
        }

        try{
              await this.dataSvc.saveCXConfig(payloadCrm, this.core.user.userId)  
              this.core.progressState = 'determinate'
              this.connected = true
          }catch(err){
            console.error(err)
            this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', 'Failed to save settings.')
        }

    }catch(err) {
      this.connected = false
      if(err.status == '401')
          this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', 'Invalid host or API. Authentication Failure.')
      else
        this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', err.status + ':' + err.statusText)
     }

     this.core.progressState = 'determinate'

   }

   saveOracle(){
    if(this.validationRules() == false){
      return
    }
    
    this.saveConfig('basic')
   }
  
   /**
   * This method validates the config
   */
  validateConfig(){
    if(this.validationRules() == false){
      return
    }

    this.core.progressState = 'indeterminate'
    this.oec.validateOpty(this.endpoint.value.trim(), this.username.value, this.password.value).subscribe(
      (resp)=> {
                  console.log("good work")
                    this.saveConfig('basic')
                    
                  this.core.progressState = 'determinate'
                  this.snackBar.open("Connection successful",'', {
                    duration: 1500,
                  });
                  console.log("user key after validation is:")
              },
      (error) => {
                    console.error(error)
                    this.core.progressState = 'determinate'
                    this.core.showMessage(GlobalVariables.ERROR, 'Connection Failed', error.statusText)
              }
    )
  }

 /**
  * this method saves the OEC REST credentials and end point
  */
  async saveConfig(cxAuthType:string){
    //alert(this.endpoint.value + ":" + this.username.value + ":" + this.password.value)
    if(this.validationRules() == false){
      return
    }
          
    this.core.progressState = 'indeterminate'
    if(this.isAdaptive === undefined)
      this.isAdaptive = false

    console.log("this.isAdaptive:", this.isAdaptive)

    const payload =  { 
                        endpoint: this.endpoint.value.trim(),
                        username: this.username.value,
                        password: this.password.value,
                        cxAuthType: cxAuthType,
                        crmName: "oracle",
                        isAdaptive: this.isAdaptive
                      }

      try{
        await this.dataSvc.saveCXConfig(payload, this.core.user.userId)  
        this.core.progressState = 'determinate'
        this.snackBar.open("Settings saved",'', {
                        duration: 1500,
                      });
      }catch(err){
        console.error(err)
        this.snackBar.open("Failed to save settings",'', {
          duration: 1500,
        });
      }
     
  }
            
            
  /**
  * Shows required field validations
  */
  getErrorMessage() {
    return this.endpoint.hasError('required') ? 'You must enter a value' : '';
  }


  chooseCred(val:string){
    //alert(val)
    this.secType = val

    if(val == 'basic'){
      this.basicColor = "primary"
      this.ssoColor = ""
    }else{
      this.basicColor = ""
      this.ssoColor = "primary"
    }
  }


  /**
   * This method fetches user profile to load user data like crm config
   */
  async fetchUserProfile(){
    if(this.core.config == undefined){
      console.log("in crm-config config is null with email:", this.core.user.email)
     
      if(this.core.user.email === '')
        return

      const rsp:any  = await this.dataSvc.fetchUserProfile(this.core.user.userId)
      this.core.log("respone for fetch user profile is:::", rsp)
      if(rsp !== undefined  && rsp !== null){
        const data  = rsp
        if(data.config != undefined){
            this.core.config = new ConfigModel(data.config.username, data.config.password, data.config.endpoint, data.config.cxAuthType, data.config.jwtToken, data.config.jwtTokenLastRefresh, 
                                               data.config.crmName, data.config.isAdaptive, data.config.apiKey, data.config.freshDeskEndPoint, data.config.freshDeskApiKey,
                                               data.config.accessToken )
            const config:ConfigModel = this.core.config;
            this.username.setValue(config.username)
            this.endpoint.setValue(config.endpoint)
            this.password.setValue(config.password)
            this.crmName.setValue(config.crmName)
            this.isAdaptive = config.isAdaptive

            if(config.apiKey !== undefined)
              this.apiKey.setValue(config.apiKey)

            if(config.freshDeskEndPoint !== undefined)
              this.freshDeskEndpoint.setValue(config.freshDeskEndPoint)

            if(config.freshDeskApiKey !== undefined)
              this.freshDeskApiKey.setValue(config.freshDeskApiKey)
        }else{
          console.log("user crm config does not exist")
        }
        }/*else{
          console.log("User profile does not exist in the backend. Generate it")
         
      }*/
    }else{
      const config = this.core.config
      this.crmName.setValue(config.crmName)
      this.username.setValue(config.username)
      this.endpoint.setValue(config.endpoint)
      this.password.setValue(config.password)
      this.isAdaptive = config.isAdaptive
    } 
  }

   /**
    * not used anywhere. test code
    */
   async saveJwt(){


    try{
      const resp:any =  await this.oec.fetchJwtToken(this.endpoint.value.trim(), this.username.value, this.password.value)
      console.log("Refresh jwt on expiry is:" + resp + ":" + JSON.parse(resp).token)
      const jwtToken = JSON.parse(resp).token
      console.log("jwt resp", resp)

      this.core.progressState = 'indeterminate'
  //    let cxAuthType = 'jwt'
  
   /*   const payload =  { 
                          endpoint: this.endpoint.value,
                          username: this.username.value,
                          password: this.password.value,
                          cxAuthType: cxAuthType,
                          jwtToken : jwtToken
                        }
  
        try{
          await this.dataSvc.saveCXConfig(payload, this.core.user.userId)  
          this.core.progressState = 'determinate'
          this.snackBar.open("Settings saved",'', {
                          duration: 1500,
                        });
        }catch(err){
          this.snackBar.open("Failed to save settings",'', {
            duration: 1500,
          });
        }*/
       
    }catch(err){
      console.error('Failed with error', err)
      console.log(err.error.text)
      this.core.showMessage(GlobalVariables.ERROR, 'Error!', err.error.text)
    }


    // let newWin = window.open(endpointVal + '/fscmRestApi/tokenrelay', "hello", "width=200,height=200");

    //  console.log("new window opened")
    //  console.log(newWin.document.getRootNode())

   /*  
     console.log("making call to jwt")
     try{
          const resp =  await this.oec.fetchJwtToken(endpointVal)
          console.log("jwt resp", resp)
     }catch(err){
       console.error('Failed with error', err)
       console.log(err.error.text)
       this.dialog.open(MessageComponent, {
        width: '600px',
        height: '400px',
        panelClass: 'dialog-container',
        data: {title:"Error", message: "Error!", reason: err.error.text, iconType: "error", showReasonLabel:true}
      });
     }*/
   }

   
   openSSO(){
    if(this.endpoint.value == undefined || this.endpoint.value.trim() == ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot Save', "Endpoint cannot be empty")
      return;
    }

    window.open(this.endpoint.value.trim()+ '/fscmRestApi/tokenrelay?redirect_uri=https://localhost:4200')
   }



  ngOnInit() {
    console.log("nginit of crm-config")
    this.fetchUserProfile()
  }

}
