/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 1 2022
 * 
 * Description: Backing code to customize object response payload
 * 
 */
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AttributeDefModel } from '../model/attribute-def.model'

import { AddAttributeComponent } from '../add-attribute/add-attribute.component'
import { MessageComponent } from '../message/message.component';

import { CoreService } from '../core.service'
import { OecService } from '../oec.service'
import { MitDataService } from '../data.service';
import { GlobalVariables } from '../model/global-variables.modal';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ReplaceAttributeComponent } from '../replace-attribute/replace-attribute.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit {
  avlAttrField = new FormControl('')
  filteredOptions: Observable<any>;
  selAvAttr:any

  cxObject:string //to select a object for changing payload
  tempCrmName  = 'oracle'
  objImg = '' //to display in card preview
  templateActions:any
  objActionList:any

  constructor(public db: AngularFireDatabase,
              public core:CoreService,
              public dialog: MatDialog,
              public oecSvc: OecService,
              public snackBar: MatSnackBar,
              public dataSvc: MitDataService,
              private fns: AngularFireFunctions) { 

                this.filteredOptions = this.avlAttrField.valueChanges
                .pipe(
                  startWith(''),
                  map(value => this._filter(value))
                );

              }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.core.optyAvailableAttributes.filter( (option:any) => option.name.toLowerCase().includes(filterValue));
  }

  /**
   * opens popup to add new properties
   * @param attr 
   */
  addAttribute(attr:any){
    console.log("at add attr is", this.avlAttrField.value)

    if(this.avlAttrField.value.trim() === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Missing value', 'Please select an attribute first to add')
      return
    }

     let attrName =  this.avlAttrField.value
     let attTitle 
     let attrType
     let attrLov

    for(let aval of this.core.optyAvailableAttributes){
      if(aval.name === this.avlAttrField.value){
        console.log("found:::", aval.title)
        attTitle = aval.title
        attrType = aval.type
        attrLov = aval.lov
        break
      }
    }
    const dialogRef = this.dialog.open(AddAttributeComponent, {
      width: '450px',
      height: '350px',
      panelClass: 'dialog-container',
      data: {title: attTitle, name: attrName}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed:' + JSON.stringify(result));
      if(result != undefined && result.title != undefined){
          //if it is associated with LOV then add that too in the payload
          const attrDef = new AttributeDefModel(result.name, result.title, attrType, false, result.inFactList, attrLov)
          attrDef.removable = true
          console.log("adding attribute:", attrDef)
         //add to selected list
          this.core.optyAttributes.push(attrDef)

          //removed from available list
          var indx = 0
          var found = false
          for(let obj of this.core.optyAvailableAttributes){
            if(obj.name == result.name){
              found = true
              break
            }
            indx++
          }

          if(found == true)
            this.core.optyAvailableAttributes.splice(indx, 1)

          this.avlAttrField.setValue('')
      }
    });
  }

actionKeys(){
  
  return Object.keys(this.objActionList)
}


/**
 * left side avaiable attributes can be edited to chnage base attribute.
 * Salesforce allows out of the box attributes to be disabled. For example Probability from opportunity can be removed. 
 * FlashCX.ai templates assume these to be always present and hence this will break the overall template. So, for salesforce idea is to let user
 * replace the attribute name with some other attribute. for example Probability can be replaced with a custom attribute like Win_Probability__c 
 * 
 * If user still want to remove it completely then we let that happen. if they mess up then simply restore and start over
 * @param attr 
 */
 chipClicked(attr:any){
    if(this.core.config.crmName !== GlobalVariables.SALESFORCE)
      return

    console.log("chip clicked dude", attr)
    const dialogRef = this.dialog.open(ReplaceAttributeComponent, {
      width: '600px',
      height: '450px',
      panelClass: 'dialog-container',
      data: {title: attr.title, name: attr.name}
    });

    //take updated value on save
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed:' + JSON.stringify(result));
      if(result != undefined && result.name != undefined){
        attr.name = result.name
      }
    });
  }

  isRemovable(title:string){
    if(title == 'Name')
      return false
    else
      return true
  }

  ifAttrExist(payloadAttr:string){
    const found = this.core.optyAttributes.find(function(element){
      return element.name == payloadAttr
    })

    if(found == undefined)
      return false

    return true
  }

  /**
   * this method removes the attribute from the selected list and adds it back to the available list
   * @param attr 
   */
  removeAttr(attr){
    console.log("removing:" + attr.name)
    //alert('removing')
    //remove from selected list

    if(this.core.progressState == 'indeterminate'){
      this.dialog.open(MessageComponent, {
        width: '400px',
        height: '260px',
        panelClass: 'dialog-container',
        data: { title:"Error", message: "Cannot remove attribute", iconType: "error",
                reason: "The page is still being loaded.", showReasonLabel:true, showErrorLabel:true}
      })

      return
    }
    var indx = 0
    var found = false
    let foundObj = undefined
    for(let obj of this.core.optyAttributes){
      if(obj.name === attr.name){
        found = true
        foundObj= obj
        break
      }
      indx++
    }

    if(found == true){
      this.core.optyAttributes.splice(indx, 1)

      //add it to the available list
      this.core.optyAvailableAttributes.unshift(foundObj)
    }
    
  }

  /**
   * This method shows and hides the action buttons
   */
  showHideActions(act){
    this.core.log("key is:", act)

    if(this.templateActions[this.cxObject][act] === false)
      this.templateActions[this.cxObject][act] = true
    else
      this.templateActions[this.cxObject][act] = false
  }

  /**
   * Fires when object is changed 
   * @param val 
   */
  cxObjectChanged(val){
    console.log("new value is:" + val)
    this.cxObject = val
    this.refreshObject()

    if(this.cxObject === 'opportunities'){
      this.objImg = "https://firebasestorage.googleapis.com/v0/b/salesbot-slack.appspot.com/o/60.png?alt=media&token=52e6dfa5-97db-4538-bba8-1e64b73096f8"

      
    }else if(this.cxObject === 'leads')
      this.objImg = "https://firebasestorage.googleapis.com/v0/b/salesbot-slack.appspot.com/o/hotlead.png?alt=media&token=13502278-eaf8-4981-8efd-56fb043f8c9a"
    else
      this.objImg = "https://res.cloudinary.com/moblizeit/image/upload/v1574791573/worker.png"
  }

  getObjectDisplayName(name:string){
    return name.charAt(0).toUpperCase() + name.substring(1)
  }

  imgLayout(objectName:string){
    if(objectName === 'contacts' || objectName === 'leads' || objectName === 'opportunities')
      return true
    
    return false
  }

  /**
   * This will fetch the current template of the selected object and will get attributes from the object describe
   */
  async refreshObject(){
    //rest arrays
    this.core.optyAttributes = []
    this.core.optyAvailableAttributes = []

    //get current template
    const userKey = this.core.user.userId
    this.core.log("userKey:", userKey)

    //populate attributes from the template into available list
    let objTempResp = await this.dataSvc.fetchUserTemplates(userKey, this.core.getObjectName(this.tempCrmName, this.cxObject))

    this.core.log("objTemplate is::::", objTempResp)
    if(objTempResp === null){
        //the template is not available as might have been created later than this user. so fetch and copy
        const respO:any = await this.dataSvc.fetchGlobalTemplates(this.tempCrmName)
        this.core.log("data response is:::", respO)
        //now copy this template to the user node to avoid pulling from global template every time
        await this.dataSvc.copyTemplate(respO, userKey)
        console.log("copied template from global. regular business now")
        objTempResp = await this.dataSvc.fetchUserTemplates(userKey, this.core.getObjectName(this.tempCrmName, this.cxObject))
    }

    this.core.log("objTempResp", objTempResp)
    //populate available attributes based on template
    for(let key of Object.keys(objTempResp)){
      //this.core.log("pushing::::" + key + ' for ', objTempResp[key])
      this.core.optyAttributes.push(objTempResp[key]) 
    }

      //get object describe
      let data:any
      if(this.tempCrmName === GlobalVariables.SALESFORCE){
        data = await (await this.oecSvc.doObjectDescribe(this.core.getObjectName(this.tempCrmName, this.cxObject))).toPromise()
        this.core.progressState = 'determinate'

        this.core.log("sfdc descrbie is", data.fields)

        if(data.fields === undefined){
          this.core.showMessage(GlobalVariables.ERROR, 'Authentication Failure', 'Click on home page and reauthenticate')
          return
        }
        this.core.optyAvailableAttributes = []
          for(let attr of data.fields){
              //do not include the one that are already part of the payload
              if(this.ifAttrExist(attr.name) == false){
                  const attrDef = new AttributeDefModel(attr.name, attr.label, attr.type, false, true, undefined)
                  this.core.optyAvailableAttributes.push(attrDef)
                }
            }
      }else if(this.tempCrmName === GlobalVariables.FRESHSALES){

        this.core.log("cx object is", this.cxObject)
        //supporting only freshdesk tickets and in that case there is no describe but another api called ticket_fields
        data = await (await this.oecSvc.doObjectDescribe(this.cxObject)).toPromise()
        this.core.progressState = 'determinate'

        this.core.log("freshsales descrbie is", data)

        this.core.optyAvailableAttributes = []

        if(this.cxObject === 'tickets'){
          //freshdesk case
          for(let attr of data){
            //do not include the one that are already part of the payload
            //all the types in case of freshdesk are treated as string
            if(this.ifAttrExist(attr.name) == false){
                const attrDef = new AttributeDefModel(attr.name, attr.label, "string", false, true, undefined)
                this.core.optyAvailableAttributes.push(attrDef)
              }
          }
        }else{
          for(let attr of data.fields){
            //do not include the one that are already part of the payload
            if(this.ifAttrExist(attr.name) == false){
                const attrDef = new AttributeDefModel(attr.name, attr.label, "string", false, true, undefined)
                this.core.optyAvailableAttributes.push(attrDef)

              }
          }

        }
       
        
          this.core.log("total available attributes are", this.core.optyAvailableAttributes.length)
      }
      else{
            //oracle
            if(this.core.config.isAdaptive === undefined || this.core.config.isAdaptive === false){
              data = await this.oecSvc.doDescribe(this.cxObject).toPromise()
              this.core.progressState = 'determinate'
      
              if(data.error != undefined){
                  setTimeout(() => this.dialog.open(MessageComponent, {
                    width: '400px',
                    height: '270px',
                    panelClass: 'dialog-container',
                    data: { title:"Error", message: "End point not initialized", iconType: "error",
                            reason: "Go to home and make sure the CRM connection exists and is valid", showReasonLabel:true}
                  }))
      
                  return
              }
      
              this.core.optyAvailableAttributes = []
              for(let attr of data.Resources[this.cxObject].attributes){
                  //do not include the one that are already part of the payload
                  if(this.ifAttrExist(attr.name) == false){
                      const attrDef = new AttributeDefModel(attr.name, attr.title, attr.type, false, true, attr.lov)
                      this.core.optyAvailableAttributes.push(attrDef)
      
                    }
                }
      
              return
            }else{
              console.log("adaptive describe")

              data = await (await this.oecSvc.doDescribeAdaptive(this.core.getObjectName(this.tempCrmName, this.cxObject))).toPromise()

          //   console.log("describe response:" + JSON.stringify(data))
      
              this.core.optyAvailableAttributes = []
            //  const attrKeys = Object.keys(data.items[0])
              for(let attr of data.items){
        //          console.log("attribut is:", attr)
      
                  //do not include the one that are already part of the payload
                  if(this.ifAttrExist(attr.displayAttributePath) == false && attr.displayLabel !== ""){
                    let lov = undefined
                    if(attr.attributeType === 'Enum')
                      lov = attr.links[0].href
                    const attrDef = new AttributeDefModel(attr.displayAttributePath, attr.displayLabel, attr.attributeType, false, true, lov)
                    this.core.optyAvailableAttributes.push(attrDef)
                  }
                }
      
              return
            }
      }

     
      this.objActionList = this.templateActions[this.cxObject]
      this.core.log("obj action list", this.objActionList)
  }


  /**
   * This method saves the latest state of the template to firedb
   */
  async saveTemplate(){
    this.core.log("saving path:" + 'users/' + this.core.user.userId + '/templates/' + this.core.getObjectName(this.tempCrmName, this.cxObject),  this.core.optyAttributes)
    this.core.progressState = 'indeterminate'
    const userKey = this.core.user.userId

    //save action templates as well
    this.core.log("template before save actions are:", this.templateActions)
    await this.dataSvc.saveUserActionTemplates(userKey, this.templateActions)

    const itemRef = this.db.object('users/' + userKey + '/templates/' + this.core.getObjectName(this.tempCrmName, this.cxObject));
    itemRef.set(
                 this.core.optyAttributes
                ).then(
      () => {
          this.core.progressState = 'determinate'
          this.core.showMessage(GlobalVariables.SUCCESS, 'Success', 'Settings saved!')
      }
    );
  }

  /**
   * This method will revert to the OOTB template
   */
  restoreTemplate(){

    const dialogRef = this.core.showMessage(GlobalVariables.QUESTION, 'Are you sure?', 'This will override your current template and cannot be reverted.')
    dialogRef.afterClosed().subscribe( async(result) => {
      console.log('The dialog was closed', result);
      if(result !== undefined && result === 'yes'){
        console.log("user said yes")

          //this.core.showMessage(GlobalVariables.ERROR, "Template cannot be restored", "This feature is coming soon. Email us at support@moblize.it if you want us to priortize it.")
          var overrideFunction = this.fns.httpsCallable('overrideObjectTemplate');
          overrideFunction({"crmName" : this.core.config.crmName, "objName": this.core.getObjectName(this.tempCrmName, this.cxObject), "userId": this.core.user.userId}).subscribe(res => {
            console.log("overrideFunction results:", res)

            //if all good then refresh it
            this.core.showMessage(GlobalVariables.SUCCESS, 'Completed', "Template reset successfully")
            this.refreshObject()

          });
      }else{
        console.log("user said no")
      }
    });
  }

  async ngOnInit() {
    console.log("start of ngOnInit in customize")
    if(this.core.config === undefined){
      this.dialog.open(MessageComponent, {
        width: '500px',
        height: '280px',
        panelClass: 'dialog-container',
        data: { title:"Error", message: "CRM connection not setup.", iconType: "error",
                reason: "Please setup a CRM connection first before using this feature.", 
                showReasonLabel:true, showErrorLabel:true}
      })
      this.core.launchMenu('home', 'CRM Configuration')
      return
    }

    //check if user has already done any action customization. if yes then pull those else use global actions
    this.templateActions = await this.dataSvc.fetchUserTemplateActions(this.core.user.userId)
    this.core.log("user actions", this.templateActions)

    if(this.templateActions === null || this.templateActions === undefined){
      //also fetch global actions. if a user has customized then those will be stored against user profile else a global list will be added
      this.templateActions = await this.dataSvc.fetchTemplateActions()
      this.core.log("template actions", this.templateActions)
    }

    if(this.core.config.crmName === GlobalVariables.ORACLE && this.core.config.isAdaptive === true)
      this.tempCrmName = 'oracle_adaptive'
    else
      this.tempCrmName = this.core.config.crmName


    if(this.cxObject === undefined || this.cxObject === '')
      return
      
    if(this.core.optyAvailableAttributes != undefined && this.core.optyAvailableAttributes.length > 0)
      return

    this.core.progressState = 'indeterminate'
    if(this.core.optyAvailableAttributes == undefined)
      this.core.optyAvailableAttributes = []

    if(this.core.optyAttributes == undefined)
      this.core.optyAttributes = []   
  }

}
