/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Sep 11 2020
 * 
 * Description: Config model
 * 
 */
export class ConfigModel{
    private _username:string
    private _password:string
    private _endpoint:string
    private _cxAuthType:string
    private _jwtToken:string
    private _jwtTokenLastRefresh:number
    private _crmName:string
    private _isAdaptive:boolean

    private _apiKey:string
    private _freshDeskApiKey:string
    private _freshDeskEndPoint:string

    private _accessToken:string //for salesforce

    constructor(username:string, password:string, endpoint:string, cxAuthType:string, jwtToken:string, jwtTokenLastRefresh:number, crmName:string, isAdaptive:boolean, 
                apiKey:string, freshDeskEndPoint:string, freshDeskApiKey:string, accessToken:string){
        this._username = username
        this._password = password
        this._endpoint = endpoint
        this._cxAuthType = cxAuthType
        this._jwtToken = jwtToken
        this._jwtTokenLastRefresh = jwtTokenLastRefresh
        this._crmName = crmName
        this._isAdaptive = isAdaptive
        this._apiKey = apiKey
        this._freshDeskApiKey = freshDeskApiKey 
        this._freshDeskEndPoint = freshDeskEndPoint
        this._accessToken = accessToken
    }

    get username():string{
        return this._username
    }

    get password():string{
        return this._password
    }

    get endpoint():string{
        return this._endpoint
    }

    get cxAuthType():string{
        return this._cxAuthType
    }

    get jwtToken():string{
        return this._jwtToken
    }

    get jwtTokenLastRefresh():number{
        return this._jwtTokenLastRefresh
    }

    get crmName():string{
        return this._crmName
    }

    get isAdaptive():boolean{
        return this._isAdaptive
    }

    set isAdaptive(val:boolean){
        this._isAdaptive = val
    }

    get apiKey():string{
        return this._apiKey
    }

    get freshDeskEndPoint():string{
        return this._freshDeskEndPoint
    }

    get freshDeskApiKey():string{
        return this._freshDeskApiKey
    }

    get accessToken():string{
        return this._accessToken
    }
}