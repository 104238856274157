<div class="fixActionRow">

<div fxLayout="row" fxLayoutAlign=" center" style="background-color: #4A2C82;color:white;padding-left:20px">
      <div><mat-icon *ngIf="iconType=='error'" style="color:red">error_outline</mat-icon>
           <mat-icon *ngIf="iconType=='confirm'" style="color:white">info_outline</mat-icon>
      </div>
      <div style="padding-top:15px"><h2 mat-dialog-title>&nbsp;{{title}}</h2></div>
  </div>

<mat-dialog-content >
  <div fxLayout="column" style="padding:20px">
     <div fxLayout="row" fxFlex="100%">
        <div *ngIf="showErrorLabel==true" style="color:red;font-weight:bold;width:50px;font-size: large;padding-right:10px">Error:</div>  
        <div style="font-size: large">{{text}}</div>
     </div>
     <div fxLayout="row" fxFlex="100%" style="padding-top:10px;padding-bottom: 20px">
        <div *ngIf="showReasonLabel==true" style="font-weight:bold;color:#4A2C82;width:50px;padding-right:10px">Reason:</div>  
        <div>{{reason}}</div>
     </div>
  </div>
</mat-dialog-content>
<div class="spacer"></div>
<mat-dialog-actions style="border-top:1px solid lightgrey;min-height: 100px;">
  <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" style="padding-right:10px">
      <button mat-raised-button color="accent" mat-dialog-close>Ok</button>
  </div>
</mat-dialog-actions>
<div>
