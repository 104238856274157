/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Feb 24 2021
 * 
 * Description: Factory to switch between flashcx and scanbuddy.ai firebase instance. currently used only for sb instance
 * 
 */
import { NgZone } from '@angular/core';

import { environment } from '../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';

export function AngularFireDatabaseProject1(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(environment.firebase, 'firebase', null, platformId, zone);
}

export function AngularFireDatabaseSB(platformId: Object, zone: NgZone) {
    return new AngularFireDatabase(environment.sbFirebase, 'sbFirebase', null, platformId, zone);
}
