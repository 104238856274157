<div fxLayout="row" style="padding-top: 5px;" fxLayoutGap="15px">
    <div fxLayout="row">
        <mat-form-field  appearance="outline" style="width: 700px;" required>
            <mat-label>Select field</mat-label>

            <ng-container *ngIf="core.config.isAdaptive === true">
                <input type="text"
                    placeholder="Select field"
                    matInput
                    [formControl]="wfCondField"
                    [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let attr of filteredOptions | async" value="{{attr.name}}">
                                {{attr.name}}
                            </mat-option>
                        </mat-autocomplete> 
            </ng-container>
            <ng-container *ngIf="core.config.isAdaptive !== true">
                <mat-select [formControl]="wfCondField" (selectionChange)="cxAttrChanged($event.value)">
                    <mat-option *ngFor="let attr of objAttributes" [value]="attr.name">{{attr.title}}</mat-option>
                </mat-select>
                
            </ng-container> 
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field  appearance="outline" style="width: 150px;" required>
            <mat-label>Operator</mat-label>
            <mat-select [formControl]="wfCondOper">
              <mat-option value="equals">Equals</mat-option>
              <mat-option value="notequals">Not equals</mat-option>
              <mat-option *ngIf="core.config.crmName !== 'salesforce'" value="contains">Contains</mat-option>
              <mat-option value="greaterthan">Greater Than</mat-option>
              <mat-option value="lessthan">Less Than</mat-option>
              <mat-option value="startsWith">Starts With</mat-option>
              <mat-option value="endsWith">Ends With</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field  appearance="outline" required>
            <mat-label>Value</mat-label>
            <input *ngIf="currentAttr === undefined || currentAttr.type !== 'date'" matInput placeholder="value"  [formControl]="wfCondValue" required>

            <mat-select *ngIf="currentAttr !== undefined && currentAttr.type === 'date'" [formControl]="wfCondValue">
                <mat-option value="today">Today</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center">
        <button mat-icon-button color="success" (click)="validateRow()" [disabled]="qrow.validated">
            <mat-icon style="cursor: pointer;">check_circle</mat-icon>
        </button>&nbsp;
        <button mat-icon-button color="warn" (click)="deleteRow()">
            <mat-icon style="cursor: pointer;">remove_circle</mat-icon>
        </button>
        
    </div>

</div>