/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jun 23 2020
 * 
 * Description: Let save crm credentials 
 * 
 */
import { Component, OnInit } from '@angular/core';
import { OecService } from '../oec.service';
import { CoreService } from '../core.service';


@Component({
  selector: 'app-enrichment',
  templateUrl: './enrichment.component.html',
  styleUrls: ['./enrichment.component.scss']
})
export class EnrichmentComponent implements OnInit {

  totalAccountsSet:any
  empCountAccountsSet:any
  industryAccountsSet:any
  urlAccountsSet:any
  errors:string[]  //logs error for adaptive calls
  startProgress:boolean

  constructor(public oec: OecService,
              public core:CoreService) {
    this.startProgress = false
   }

  async analyze(){
    console.log("Start analysis")
    this.errors = []
    if(this.core.config.isAdaptive == true){
      console.log("start adaptive search")
      this.doAdaptiveSearch()
    }else{
      console.log("do classic search")
      this.getStat()
    }
  }

  async doAdaptiveSearch(){
    this.startProgress = true
    this.totalAccountsSet = undefined
    this.empCountAccountsSet = undefined
    this.urlAccountsSet = undefined
    this.industryAccountsSet = undefined

    const resp:any = await this.oec.getAdaptiveAccountCount([])
    this.totalAccountsSet = {
      "total": resp.totalResults,
      "pending": 0,
      "color" : 'rgba(55,137,168,1)',
      "labels": ['Accounts'],
      "centerText": resp.totalResults
    }

    //get with no website
    let criteria = [{
      "op": "$npr",
      "attribute": "PrimaryURL"
    }]

    const resp1:any = await this.oec.getAdaptiveAccountCount(criteria)
    let missWebPerc = ((+resp1.totalResults)/+resp.totalResults)*100
    console.log("missing web diff:", +resp.totalResults - +resp1.totalResults)
    this.empCountAccountsSet = {
      "total":  +resp1.totalResults,
      "pending": +resp.totalResults - +resp1.totalResults,
      "color" : 'rgba(240,151,55,1)',
      "labels":  ['No Website'],
      "centerText": missWebPerc.toFixed(2) + '%'
    }


    //missing industry
    criteria = [{
      "op": "$npr",
      "attribute": "PrimaryIndustry"
    }]

    try{
      const resp2:any = await this.oec.getAdaptiveAccountCount(criteria)
      this.industryAccountsSet = {
        "total":  +resp2.totalResults,
        "pending": +resp.totalResults - +resp2.totalResults,
        "color" : 'rgba(240,151,55,1)',
        "labels":  [resp2.totalResults + ' No Industry']
      }
    }catch(err){
      //alert(err)
      console.error("in industry ", err.error.Error)
      this.errors.push(err.error.Error)
    }


    //missing employees
    criteria = [{
      "op": "$npr",
      "attribute": "EmployeesTotal"
    }]

    try{
      const resp3:any = await this.oec.getAdaptiveAccountCount(criteria)
      let missEmpPerc = (+resp.totalResults - +resp3.totalResults)/100
      this.empCountAccountsSet = {
        "total":  +resp3.totalResults,
        "pending": +resp.totalResults - +resp3.totalResults,
        "color" : 'rgba(240,151,55,1)',
        "labels":  ['No Employee Count'],
        "centerText": missEmpPerc + '%'
      }
    }catch(err){
      //alert(err)
      console.error("in emp ", err.error.Error)

      this.errors.push(err.error.Error)
    }
   
    this.startProgress = false
  }

  /**
   * This method performs classic adfbc search
   */
  async getStat(){
    const resp:any = await this.oec.getAccountCount()
    this.totalAccountsSet = {
      "total": resp.totalResults,
      "pending": 0,
      "color" : 'rgba(55,137,168,1)',
      "labels": [resp.totalResults + ' Total Accounts']
    }

    const resp1:any = await this.oec.getIncompleteAccountCount('')
    this.empCountAccountsSet = {
      "total":  +resp1.totalResults,
      "pending": +resp.totalResults - +resp1.totalResults,
      "color" : 'rgba(240,151,55,1)',
      "labels":  [resp1.totalResults + ' Missing Emp Count']
    }

/*  using non adaptive search website or industry is not possible

   // const resp2:any = await this.oec.getIncompleteAccountCount('')
   const resp2 = {
    totalResults : 4000
   }

    this.industryAccountsSet = {
      "total":  +resp1.totalResults,
      "pending": +resp.totalResults - +resp2.totalResults,
      "color" : 'rgba(165,95,109,1)',
      "labels":  [resp2.totalResults + ' Missing Industry']
    }

  // const resp3:any = await this.oec.getIncompleteAccountCount('')
   const resp3 = {
    totalResults : 500
   }

    this.urlAccountsSet = {
      "total":  +resp1.totalResults,
      "pending": +resp.totalResults - +resp3.totalResults,
      "color" : 'rgba(250,228,118,1)',
      "labels":  [resp3.totalResults + ' Missing Website']
    }

    */
  }

   ngOnInit() {
      this.errors = []
      //this.errors.push("Could not find field 'PrimaryIndustry' in entity 'Account'")
  }
}
