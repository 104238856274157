<div fxLayout="column" style="width:100%;border-top: 3px solid #673DB6;padding-left:10px;padding-top:5px">
    <div fxLayout="row" fxLayoutAlign=" center">
        <div style="padding-top:15px"><h2 mat-dialog-title>&nbsp;Attribute Properties</h2></div>
    </div>
  
  <mat-dialog-content>
    <div fxLayout="column" style="padding-left:20px;padding-right: 20px">
        <mat-form-field>
            <input matInput placeholder="Display Label"  [formControl]="title" required floatLabel='always'>
            <mat-error *ngIf="title.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Attribute Name (read only)"  [formControl]="name" readonly disabled floatLabel='always'>
        </mat-form-field>
            <mat-slide-toggle
              color="primary"
              [checked]="shortField">
              Show in facts?
            </mat-slide-toggle>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions >
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center" style="padding-top:10px">
        <button mat-raised-button color="primary" (click)="addAttribute()">Add</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-actions>
  </div>
  