/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 1 2022
 * 
 * Description: workflow component
 * 
 */
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreService } from '../core.service';
import { CreateWorkflowComponent } from '../create-workflow/create-workflow.component';
import { MitDataService } from '../data.service';
import { WorkflowModal } from '../model/workflow.modal';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';


export interface WfObject {
  objName: string;
  wfName: string;
  wfDescription: string;
  enabled: boolean;
  removable:boolean;
  key:string,
  query:string,
  channel:string,
  channelId:string
}

 let WF_DATA: WfObject[] = [];


@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.css']
})
export class WorkflowsComponent implements OnInit {
  ent:any
  entKey:string
  workflows = []
  pageSize=10

  @ViewChild('TABLE', {static: false}) table: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['objName', 'wfName', 'enabled', 'key'];
  dataSource = new MatTableDataSource<WfObject>(WF_DATA);


  constructor(public dataSvc: MitDataService,
              public core: CoreService,
              public dialog: MatDialog,
              private changeDetectorRefs: ChangeDetectorRef) { }

  async fetchWorkflows(){
    const rsp:any  = await this.dataSvc.fetchWorkflows(this.entKey)
    //this.core.log("rsp inside the fetch workflow is", rsp)

    this.workflows = []
      for(let key of Object.keys(rsp)){
        const object = rsp[key]
        //this.core.log("in fetch obhect", object)
        //push either out of the box or for that crm only
        if(object._removable === false || object._crm === this.core.config.crmName){
          const wfObj = new WorkflowModal(object.key, object._name, object._description, object._enabled, object._removable, object._object, object._query, object._channel, object._channelId)
          this.workflows.push(wfObj)
        }
      }



      WF_DATA = []
     for(let cRow of rsp){
       //console.log("row is::",cRow)
       WF_DATA.push(
         {objName: this.convertObjCodeToName(cRow._object), wfName: cRow._name, wfDescription: cRow._description, 
          enabled: cRow._enabled, removable: cRow._removable, key: cRow.key, query: cRow._query, channel: cRow._channel, channelId: cRow._channelId}
       ) 
     }
 
     this.dataSource = new MatTableDataSource<WfObject>(WF_DATA);
     this.refreshTbl();
 
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;

    //this.core.log("workflows left are:", this.workflows.length)
  }

  convertObjCodeToName(obj:string){
    if(obj === 'opty')
      return 'Opportunity'
    else if(obj === 'account')
      return 'Account'
    else if(obj === 'lead')
      return 'Lead'
    
    return obj
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  refreshTbl(){
    this.changeDetectorRefs.detectChanges();
  }

  /**
   * Adds a new workflow
   */
  addWorkflow(){
    const dialogRef = this.dialog.open(CreateWorkflowComponent, {
      width: '100%',
      height: '100vh',
      data: {entKey: this.entKey},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result !== undefined){
        //refresh the workflows
        this.fetchWorkflows()
      }
      
    });
  }


  /**
   * when workflow is enabled or disabled
   * @param workflow 
   */
  async wfChanged(workflow){
    console.log("changed", workflow.key + ":" + this.entKey + ":" + workflow.enabled)
    if(workflow.enabled === true)
      workflow.enabled = false
    else
      workflow.enabled = true

    console.log("workflow object is:", workflow)
    const wFlow = new WorkflowModal(workflow.key, workflow.wfName, workflow.wfDescription, workflow.enabled, workflow.removable, workflow.objName, workflow.query, workflow.channel, workflow.channelId)
    await this.dataSvc.updateWorkflow(this.entKey, workflow.key, wFlow)

    this.fetchWorkflows()

  }

  async deleteWf(workflow){
    this.core.log("start of delete:", workflow)
    await this.dataSvc.deleteWorkflow(this.entKey, workflow.key)
    this.core.log("deleted","")
    this.fetchWorkflows()
  }


  async ngOnInit() {
    const rsp:any  = await this.dataSvc.fetchEnterpriseProfileByEmail(this.core.user.email)
    this.core.log("enterprise is:", rsp[0])
     this.ent = rsp[0]
    this.entKey = this.ent.key
    this.fetchWorkflows()
    
  }

}
