/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 18 2021
 * 
 * Description: This code manages the enterprise
 * 
 */
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { CoreService } from '../core.service';
import { CreateEnterpriseComponent } from '../create-enterprise/create-enterprise.component';
import { MitDataService } from '../data.service';
import { UpdateEnterpriseLicenseComponent } from '../update-enterprise-license/update-enterprise-license.component';


export interface EntObject {
  company: string;
  adminEmail: string;
  adminName: string;
  licenseType: string;
  users:number;
  expiryDate:string;
  domain:string;
  logo:string;
  role:string;
  key:string
}

 let ENT_DATA: EntObject[] = [];

@Component({
  selector: 'app-manage-enterprise',
  templateUrl: './manage-enterprise.component.html',
  styleUrls: ['./manage-enterprise.component.css']
})
export class ManageEnterpriseComponent implements OnInit {

  pageSize=10

  @ViewChild('TABLE', {static: false}) table: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
 
  displayedColumns: string[] = ['company', 'adminEmail', 'adminName', 'licenseType', 'users', 'expiryDate', 'domain','key', 'key1'];
  dataSource = new MatTableDataSource<EntObject>(ENT_DATA);
  licenseStatus:string


  constructor(private dataSvc:MitDataService,
              private core:CoreService,
              private dialog:MatDialog,
              private changeDetectorRefs: ChangeDetectorRef) { 

        this.licenseStatus = "active"
    }


  getUserCount(ent:any){
    if(ent.users === undefined)
      return 0

    
    return  Object.keys(ent.users).length
  }


  changeLicenseValue(){
    console.log("new value is:::" + this.licenseStatus)
    this.refreshEnt()
  }

  manageUsers(ent){
    /* TODO IMPLEMENT FROM SCANBUDDY.AI
    console.log("users to manage for:" + JSON.stringify(ent))
    const dialogRef = this.dialog.open(ManageEnterpriseUsersComponent, {
      width: '1080px',
      data: {ent: ent},
      disableClose: true
    });

    */
  }


  /**
   * 
   * This method upgrades the license of a user
   * @param ent 
   */
  manageLicense(ent){
    console.log("Ent is:::", ent)
    const dialogRef = this.dialog.open(UpdateEnterpriseLicenseComponent, {
      width: '580px',
      data: {ent: ent},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.refreshEnt()
    });
  }

  updateEnterprise(ent){
    /**   TODO IMPLEMENT FROM SCANBUDDY.AI
    const dialogRef = this.dialog.open(UpdateEnterpriseComponent, {
      width: '580px',
      data: {ent: ent},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.refreshEnt()
    });

    */
  }
  
  addEnterprise(){
    const dialogRef = this.dialog.open(CreateEnterpriseComponent, {
      width: '580px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.refreshEnt()
    });
  }



  refresh() {
    this.changeDetectorRefs.detectChanges();
  }


  async refreshEnt(){
    const resp:any = await this.dataSvc.fetchEnterprises(this.licenseStatus)
     console.log("ent keys::::", resp)

     ENT_DATA = []
     for(let cRow of resp){
       //console.log("row is::",cRow)
      ENT_DATA.push(
         {company: this.core.pretifyAllNames(cRow._company), adminEmail: cRow._adminEmail, adminName: this.core.pretifyAllNames(cRow._adminName), 
          licenseType: this.core.pretifyAllNames(cRow._licenseType),users: this.getUserCount(cRow), expiryDate: this.core.pretifyDateRemaining(cRow._expiryDate), 
          domain: cRow._domain,logo: cRow._logo, role: cRow._role, key: cRow.key}
       ) 
     }
 
     this.dataSource = new MatTableDataSource<EntObject>(ENT_DATA);
     this.refresh();
 
     this.dataSource.paginator = this.paginator;

  }

  ngOnInit() {
    this.refreshEnt()
  }
}
