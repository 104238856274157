/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Dec 8 2019
 * 
 * Description: Backing code to managee billing
 * 
 */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';

import { MessageComponent } from '../message/message.component';

import { CoreService } from '../core.service'

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  plan:string
  monthlyPlanColor:string
  annualPlanColor:string
  pricing:any

  constructor(public core:CoreService,
               public dialog: MatDialog,
               public db: AngularFireDatabase) { 
    this.plan = "annual"
    this.monthlyPlanColor = ""
    this.annualPlanColor = "primary"
    this.pricing = {
      "team" : {
        "monthly": "$4.99",
        "annual" : "$49.99"
      },
      "pro" : {
        "monthly": "$8.99",
        "annual" : "$89.99"
      },
      "ninja" : {
        "monthly": "$19.99",
        "annual" : "$199.99"
      }
    }
  }


  /**
   * upgrade to a paid plan
   */
  upradePlan(planName:string){
    //alert('upgrading to:' + planName)

    this.core.progressState = 'indeterminate'
    const userKey = 'dummy'//this.core.createUserKey()
    const itemRef = this.db.object('plan_upgrades/' + userKey);
    itemRef.set(
                  { 
                    name: this.core.user.name,
                    email: this.core.user.email,
                    team: this.core.user.teamName,
                  }
                ).then(
      () => {
          this.core.progressState = 'determinate'
          //show confirmation
          const dialogRef = this.dialog.open(MessageComponent, {
            width: '400px',
            height: '260px',
            panelClass: 'dialog-container',
            data: {title:"Confirmation", iconType: "confirm", message: "Message Sent!", reason: "A team member will get back to you shortly!",
                   showReasonLabel:false, showErrorLabel:false}
          });
      }
    );
   
   
  }


  getDaysLeft(){
    //return (this.core.accountStartDate | amAdd: 30 : 'days' | amDifference: today :'days' : true) +  'days left'


    return '30'
  }

  choosePlan(plan:string){
   if(plan == "annual"){
    this.plan = plan
    this.monthlyPlanColor = ""
    this.annualPlanColor = "primary"
   }else{
    this.plan = plan
    this.monthlyPlanColor = "primary"
    this.annualPlanColor = ""
   }
  }

  ngOnInit() {
  }

}
