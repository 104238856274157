<div fxLayout="row wrap" fxLayoutAlign="center" style="padding:20px">
  <mat-card style="width: 100%">
        <mat-card-title>Select an object to begin</mat-card-title>
      <mat-card-content>
<div fxLayout="column" >
    <div fxLayout="row" fxFlex fxLayoutAlign="start">
        <mat-form-field floatLabel='never' appearance="outline">
            <mat-label>Select Object</mat-label>
            <mat-select (selectionChange)="cxObjectChanged($event.value)">
              <mat-option *ngIf="core.config.crmName !== 'freshsales'" value="accounts">Account</mat-option>
              <mat-option *ngIf="core.config.crmName !== 'freshsales'" value="contacts">Contact</mat-option>
              <mat-option *ngIf="core.config.crmName !== 'freshsales'" value="leads">Lead</mat-option>
              <mat-option *ngIf="core.config.crmName !== 'freshsales'" value="opportunities">Opportunity</mat-option>
              <mat-option *ngIf="core.config.crmName === 'freshsales'" value="opportunities">Deals</mat-option>
              <mat-option *ngIf="core.config.crmName !== 'salesforce'" value="tickets">Ticket (SR)</mat-option>
            </mat-select>
          </mat-form-field>
    </div>

    <ng-container *ngIf="core.optyAttributes !== undefined && cxObject !== undefined && cxObject !== ''">
            <div fxLayout="row" fxFlex fxLayoutAlign="left">
                <mat-card style="width: 100%;">
                  <mat-card-title>Modify template properties</mat-card-title>
                  <mat-card-subtitle>Selected attributes on the left side makes the final template. Some of the properties cannot be removed.</mat-card-subtitle>
  
                  <mat-card-content style="padding-top: 20px;">
                    <div fxLayout="column">
                      <div class="cbh-dialog-title sg-text-color">Add attributes</div>
                      <div fxLayout="row" fxLayoutAlign="start ">
                            <div fxLayout="row">
                              <mat-form-field  appearance="outline" style="width: 300px;">
                                <mat-label>Search attribute to add</mat-label>
                    
                              <input type="text"
                              placeholder="Select attribute"
                              matInput
                              [formControl]="avlAttrField"
                              [matAutocomplete]="auto">
                                  <mat-autocomplete #auto="matAutocomplete">
                                      <mat-option *ngFor="let attr of filteredOptions | async" value="{{attr.name}}">
                                          {{attr.title}}
                                      </mat-option>
                                  </mat-autocomplete> 
                              </mat-form-field>
                            </div>
                            <div fxLayout="row">
                              <button mat-flat-button color="primary" (click)="addAttribute(attr)" style="height: 50px;margin-top: 5px;"><mat-icon>add_circle</mat-icon>&nbsp;Add</button>
                            </div>
                      </div>

                      <div fxLayout="row" fxFlex fxLayoutAlign="space-around ">
                        <div fxLayout="column" fxLayoutGap="5px" style="width: 90%;">
                          <div class="cbh-dialog-title sg-text-color">Selected attributes ({{core.optyAttributes.length}})</div>

                          <mat-list [style.overflowX]="'hidden'" [style.overflowY]="'auto'" [style.height.px]="'300'">
                          <mat-list-item *ngFor="let attr of core.optyAttributes">
                            <p mat-line>{{attr.title}}</p>
                            <button class="cust-btn" mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                             
                              <mat-icon>more_vert</mat-icon>
      
                            </button>
                            <mat-divider></mat-divider>

                            <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
                              <button mat-menu-item (click)="chipClicked(attr)">
                                <mat-icon>edit</mat-icon>
                                <span>Edit</span>
                              </button>
                              <button mat-menu-item  *ngIf="(attr.removable) == true" (click)="removeAttr(attr)">
                                <mat-icon color="warn">delete</mat-icon>
                                <span>Remove</span>
                              </button>
                            </mat-menu>
                          </mat-list-item>
                        </mat-list>
                        
                        </div>
                        <div fxLayout="column" *ngIf="objActionList !== undefined && tempCrmName !== 'freshsales'" style="width: 400px;padding-left:20px">
                          <div class="cbh-dialog-title sg-text-color">Actions</div>
                          <mat-list>
                            <mat-list-item *ngFor="let act of actionKeys()">
                              <button mat-icon-button (click)="showHideActions(act)">
                                <mat-icon *ngIf="objActionList[act] === true" mat-list-icon color="primary" >check_circle</mat-icon>
                                <mat-icon *ngIf="objActionList[act] === false" mat-list-icon  style="color:lightgray">check_circle</mat-icon>

                              </button>
                              <div mat-line>{{core.pretifyAllNames(act)}}</div>
                              <mat-divider></mat-divider>
                            </mat-list-item>
                          </mat-list>
                          
                        </div>
                       
                        <div style="padding-left: 30px;">
                          <mat-card style="width:400px"> 
                            <mat-card-title>Card Preview</mat-card-title>
                            <mat-card-content>
                              <div fxLayout="column" style="width: 100%;">
                                <div fxLayout="row" fxFlex style="padding:10px;color: black;font-size: 18px;height:40px;font-weight: bold;background-color: aliceblue;">
                                    {{getObjectDisplayName(cxObject)}}
                                </div>
                                <div fxLayout="column" fxFlex style="padding-top: 25px;">
                                  <ng-container *ngFor="let attr of core.optyAttributes">
                                    <div class="sg-text-color"  style="font-weight: bold;" *ngIf="(attr.removable) == false && attr.name.toLowerCase() !== 'id'">{{attr.title}}</div>
                                  </ng-container>
                                </div>
                                <div fxLayout="row">
                                    <div fxLayout="row" fxFlex="60px" style="padding-top: 20px;" *ngIf="imgLayout(cxObject) === true">
                                      <img [src]="objImg" width="48px" height="48px">
                                    </div>
                                    <div fxLayout="column" style="padding-top: 15px;">
                                      <ng-container *ngFor="let attr of core.optyAttributes">
                                        <div fxLayout="row" fxFlex *ngIf="(attr.removable) == true">
                                          <div fxLayout="row" style="font-weight: bold;padding-left:20px;width: 150px;">{{attr.title}}</div><div style="padding-left: 10px;">&lt;value&gt;</div>
                                        </div>
                                      </ng-container>
                                  </div>
                                </div>
                               
                              </div>
                            </mat-card-content>
                            <mat-card-actions>
                              <div fxLayout="row wrap" fxLayoutGap="5px" *ngIf="objActionList !== undefined && tempCrmName !== 'freshsales'">
                                
                                <div *ngFor="let act of actionKeys()">
                                  <button  mat-stroked-button color="primary" *ngIf="objActionList[act] === true">{{core.pretifyAllNames(act)}}</button>
                                </div>
                               
                              </div>
                              <button *ngIf="objActionList === undefined" mat-stroked-button color="primary">Action1</button>
                            </mat-card-actions>
                          </mat-card>
                        </div>
                      </div>

                    </div>
                </mat-card-content>
                </mat-card>             
         </div>
        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center" style="padding-left:20px;padding-top:20px">
                <div fxLayout="row"  fxLayoutAlign="center" fxLayoutGap="10px" >
                    <button mat-raised-button class="cbh-wizard-button" color="primary" (click)="saveTemplate()">Save Template</button>
                    <button mat-stroked-button  (click)="restoreTemplate()">Restore Default Template</button>
                </div>
        </div>
  </ng-container>
</div>
</mat-card-content>
</mat-card>
