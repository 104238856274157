/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Feb 24 2021
 * 
 * Description: Service file to use scanbuddy project functionality
 * 
 */
import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceService {

  constructor( @Inject('firebase') private firebase: AngularFireDatabase,
  @Inject('sbFirebase') private firebaseSB: AngularFireDatabase) { }


  public getSomethingFromProject1(): void {
    
  }

  public getSomethingFromProject2(): void {
   
  }

    /**
   * Searches an industry by its name
   * @param fieldTtype 
   * @param name 
   */
  findCompanyByName(fieldTtype:string, name:string){
    console.log("Searching for valid name:::" + name)
    return new Promise((resolve, reject) => {
      this.firebaseSB.list("/config/companiesByDomains", ref => ref.orderByChild(fieldTtype).startAt(name).endAt(name + "\uf8ff")).snapshotChanges().pipe(
        map(changes => 
         changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}))
        )
       ).subscribe(
        (res:any) => {
            resolve(res)
        },
        err => {
            console.log(err)
            reject(err)
        }
      )}
    )
  }
}
