/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jan 19 2020
 * 
 * Description: App component file for app level init or routing logic
 * 
 */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreService } from './core.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  userId:string
  teamId:string

  constructor(private route: ActivatedRoute,
              private router:Router,
              private core:CoreService){
    //if user param is available query from firebase for the credentials and navigate to dashboard else keep on login page
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.teamId = params['teamId'];
   });

   this.router.navigate(['']);  //ABOVE CODE WILL GO AWAY

  }


  ngOnInit() {
   
  }
}
