/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Feb 23 2021
 * 
 * Description: This is the landing site and it shows the home page with all features 
 * 
 */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from '../core.service'
import { MessageComponent } from '../message/message.component';
import { ConfigModel } from '../model/config.model';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { FirebaseUISignInFailure } from 'firebaseui-angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { MitDataService } from '../data.service';
import { UserModel } from '../model/user.model';
import { OecService } from '../oec.service';
import { GlobalVariables } from '../model/global-variables.modal';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  showMenu:boolean

  constructor(public dataSvc: MitDataService,
              public snackBar: MatSnackBar,
              public core:CoreService,
              public dialog: MatDialog,
              private afAuth: AngularFireAuth,
              private oec: OecService) {

    this.core.progressState = 'determinate'
    this.showMenu = true
  }

  launchMenu(item:string, itemLabel:string){
    this.core.currentPage = item
    this.core.currentPageLabel = itemLabel
  }

  successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
    console.log('successCallback', data);
    this.core.loggedIn = true
  }

  logout() {
    this.core.loggedIn = false
    this.afAuth.auth.signOut();
  }

  errorCallback(data: FirebaseUISignInFailure) {
    alert('Failed to login')
    this.core.loggedIn = false
    console.warn('errorCallback', data);
  }

  openSSO(){
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      height: '280px',
      panelClass: 'dialog-container',
      data: {title:"Information", message: "Coming soon", iconType: "confirm",
             reason: "Please let us know if this is a blocker for you and we will do our best to priortize it. Email us at support@moblize.it", 
             showReasonLabel:false, showErrorLabel:false}
    });
  }



  ngOnInit() {    
    this.afAuth.authState.subscribe(async d => {
      //console.log('subscribed to firebase auth' + JSON.stringify(d))
      if(d == null){
        this.core.loggedIn = false
      }else{
        this.core.loggedIn = true
          const domain = d.email.substring(d.email.indexOf("@")+1).toLowerCase().trim()
          this.core.user = new UserModel('', d.email,d.uid,'',GlobalVariables.USER, domain)
          console.log("in dashboard.components.ts Try to fetch user profile from firebase")
          const data:any = await this.dataSvc.fetchUserProfileByEmail(d.email)
          
          this.core.log("Fetched user data is:",data)

          if(data.length === 0){
            console.log("no user exists in the backend so generate one")
            const avatarUrl = 'https://firebasestorage.googleapis.com/v0/b/salesbot-slack.appspot.com/o/man64.png?alt=media&token=8f49be7c-4629-44d4-92e4-115b1053f356'
            
            const payload = {
              "email": d.email,
              "userId": d.uid,
              "avatar" : avatarUrl,  
              "name" : 'Profile',  //temp value
              "role": GlobalVariables.USER,   //by default every one will have minimum privilege called user.
              "domain": domain //required to associate all people in the same company
            }
            this.dataSvc.createUserProfile(d.uid, payload).then(
              (data) => {
                this.core.user = new UserModel('Profile',d.email,d.uid, avatarUrl, GlobalVariables.USER, domain)
              }
            )
          }else{

                const mitUser = data[0]
                this.core.log("email in existing user is:::" + mitUser.email, mitUser)
                let role = GlobalVariables.USER
                if(mitUser.role !== undefined)
                  role = mitUser.role

                let domain = mitUser.email.substring(mitUser.email.indexOf("@")+1).toLowerCase().trim()
                if(mitUser.domain !== undefined)
                  domain = mitUser.domain

                //check if enterprise setup exists. if it does then get the user role from there if it exists
                const entResp:any = await this.dataSvc.fetchEnterpriseProfileByEmail(mitUser.email)
                if(entResp.length == 1){
                  this.core.log("Enterprise profile is:", entResp)
                  //get user role at this point to define what menu access they have
                  if(entResp[0]._role !== undefined && entResp[0]._role !== '')
                    role = entResp[0]._role
                }

                this.core.log("User role is:", role)

                this.core.user = new UserModel(mitUser.name,mitUser.email,mitUser.userId, mitUser.avatar, role, domain)

                if(mitUser.config !== undefined){
                  this.core.config = new ConfigModel(mitUser.config.username, mitUser.config.password, mitUser.config.endpoint, mitUser.config.cxAuthType, 
                    mitUser.config.jwtToken, mitUser.config.jwtTokenLastRefresh,mitUser.config.crmName,mitUser.config.isAdaptive,mitUser.config.apiKey,mitUser.config.freshDeskEndPoint,
                    mitUser.config.freshDeskApiKey, mitUser.config.accessToken)
                  this.core.log("print after config set", this.core.config)
                  await this.oec.setAuthString()
                }

                this.core.log("user role is:", this.core.user.role)
          }
      }
    });
  }

}
