/**
 * this file is used only during testing. when deployed  environments.prod.ts is used
 */
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBQ1bjM12-h33K3mPGUUSoQk4Z1xXo37EA",
    authDomain: "salesbot-slack.firebaseapp.com",
    databaseURL: "https://salesbot-slack.firebaseio.com",
    projectId: "salesbot-slack",
    storageBucket: "salesbot-slack.appspot.com",
    messagingSenderId: "74940030364"
  },
  sbFirebase : {
    apiKey: "AIzaSyDF8btzg85a_zwnxbJYpv_53Ln8J6xhpKM",
    authDomain: "mitbusinesscardscanner.firebaseapp.com",
    databaseURL: "https://mitbusinesscardscanner.firebaseio.com",
    projectId: "mitbusinesscardscanner",
    storageBucket: "mitbusinesscardscanner.appspot.com",
    messagingSenderId: "1023422831715"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
