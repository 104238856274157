<div  fxLayout="column" style="width:100%;height: 100vh;" >
    <mat-card style="width: 400px;">
        <mat-card-content>
            <div fxLayout="column" fxLayoutAlign="center ">
                <div fxLayout="row" fxFlex fxLayoutAlign="center">
                    This will override all the user templates. Admin can configure once for entire organization.
                </div>
                <div fxLayout="row"  fxFlex fxLayoutAlign="center" style="padding-top: 10px;">
                    <button mat-raised-button color="primary" class="cbh-wizard-button" (click)="override()">Override custom templates</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
   
</div>