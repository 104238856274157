/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: May 7 2021
 * 
 * Description: Data Service to make calls to fireDB for different methods
 * 
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
import { WorkflowModal } from './model/workflow.modal';
@Injectable({
  providedIn: 'root'
})
export class MitDataService {

  constructor(public db:AngularFireDatabase,
              private http: HttpClient) { }


  /**
 * This method creates a new account
 * @param email 
 * @param userId 
 */
createUserProfile(userId:string, payload:any){
  return new Promise((resolve, reject) => {
    this.db.object("/users/" + userId).update(
      payload 
    ).then
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log("cannot:" + err)
      reject(err)
    }
  })
}  

/**
 * save user actions
 * @param userId 
 * @param payload 
 * @returns 
 */
saveUserActionTemplates(userId:string, payload:any){
  return new Promise((resolve, reject) => {
    this.db.object("/users/" + userId + "/templateActions").update(
      payload 
    ).then
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log("cannot:" + err)
      reject(err)
    }
  })
}  

/**
 * This method indicates to override all the user custom templates. it need to be a backgroun job so this flag will simply indicate.
 * background job will do the work and flip the indicator back to false
 * @param userId 
 */
overrideOrgTemplates(userId:string){
  return new Promise((resolve, reject) => {
    this.db.object("/users/" + userId ).update({"overrideTemplates": true}).then
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log("cannot:" + err)
      reject(err)
    }
  })
}

/**
 * updates the CX config
 * @param payload 
 * @param userId 
 */
saveCXConfig(payload:any, userId:string){
  return new Promise((resolve, reject) => {
    this.db.object("/users/" + userId + "/config").update(payload).then
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log("cannot:" + err)
      reject(err)
    }
  })
}

fetchUserProfile(key:string){
  return new Promise((resolve, reject) => {
    this.db.object('/users/' + key).valueChanges().subscribe
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log(err)
      reject(err)
    }
})
}

  /**
   * This method fetches enterprises 
   */
  fetchEnterprises(status:string){
    const  date = (new Date()).getTime()


    if(status === 'active'){
      return new Promise((resolve, reject) => {
        this.db.list("/enterprise", ref => ref.orderByChild("_expiryDate").startAt(date)).snapshotChanges().pipe(
          map(changes => 
           changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}))
          )
         ).subscribe(
          (res:any) => {
              resolve(res)
          },
          err => {
              console.log(err)
              reject(err)
          }
        )}
      )
    }
    else if(status === 'expired'){
      return new Promise((resolve, reject) => {
        this.db.list("/enterprise", ref => ref.orderByChild("_expiryDate").endAt(date)).snapshotChanges().pipe(
          map(changes => 
           changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}))
          )
         ).subscribe(
          (res:any) => {
              resolve(res)
          },
          err => {
              console.log(err)
              reject(err)
          }
        )}
      )
    }else{

      return new Promise((resolve, reject) => {
        this.db.list("/enterprise").snapshotChanges().pipe(
          map(changes => 
           changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}))
          )
         ).subscribe(
          (res:any) => {
              resolve(res)
          },
          err => {
              console.log(err)
              reject(err)
          }
        )}
      )

    }
  }


  /**
   * This method creates a new  enterprise license.  on firebase side it disables the old license and also changes the enterprise licenseType value accordingly
   */
     updateLicense(type:string, startDate:number, endDate:number, users:number, entId:string){
      return new Promise((resolve, reject) => {
        this.db.list("/enterprise/" + entId + "/licenses").push( {"_isActive": true, "_type": type, "_startDate": startDate, "_endDate": endDate, "_users": users}).then
        (
          (res:any) => {
            //console.log(JSON.stringify(res))
            resolve(res)
          }
        ),
        err => { 
          console.log(err)
          reject(err)
        }
    })
}

fetchWorkflows(entKey:string){
  return new Promise((resolve, reject) => {
    this.db.list("/enterprise/" + entKey + "/config/workflows").snapshotChanges().pipe(
      map(changes => 
       changes.map(c => ({ key: c.payload.key, ...c.payload.val()  as {}}))
      )
     ).subscribe(
      (res:any) => {
          resolve(res)
      },
      err => {
          console.log(err)
          reject(err)
      }
    )}
  )
}


/**
 * Create a new workflow
 * @param entKey 
 * @param payload 
 */
createWorkflow(entKey:string, payload:WorkflowModal){
  console.log("workflow to create", payload)
  return new Promise((resolve, reject) => {
    this.db.list('/enterprise/' + entKey + '/config/workflows/').push(payload).then
    (
      (res:any) => {
        //console.log(JSON.stringify(res))
        resolve(res)
      }
    ),
    err => { 
      console.log("cannot:" + err)
      reject(err)
    }
})
}

/**
 * Delete the selected workflow
 * @param entKey 
 * @param key 
 * @returns 
 */
deleteWorkflow(entKey:string, key:string){
    return new Promise((resolve, reject) => {
      this.db.object('/enterprise/' + entKey + '/config/workflows/' + key).remove().then
      (
        (res:any) => {
          //console.log(JSON.stringify(res))
          resolve(res)
        }
      ),
      err => { 
        console.log("cannot:" + err)
        reject(err)
      }
  })
}

/**
 * This method updates a particular workflow
 * @param entKey 
 * @param key 
 * @param payload 
 * @returns 
 */
  updateWorkflow(entKey:string, key:string, payload){
     console.log("to update workflow:", payload)
      return new Promise((resolve, reject) => {
        this.db.object('/enterprise/' + entKey + '/config/workflows/' + key).update(payload).then
        (
          (res:any) => {
            //console.log(JSON.stringify(res))
            resolve(res)
          }
        ),
        err => { 
          console.log("cannot:" + err)
          reject(err)
        }
    })
  }


/**
 * This method fetches enterprise profile by email if it exists
 * @param email 
 */
fetchEnterpriseProfileByEmail(email:string){
  return new Promise((resolve, reject) => {
    this.db.list("/enterprise", ref => ref.orderByChild('_adminEmail').equalTo(email)).snapshotChanges().pipe(
      map(changes => 
       changes.map(c => ({ key: c.payload.key, ...c.payload.val()  as {}}))
      )
     ).subscribe(
      (res:any) => {
          resolve(res)
      },
      err => {
          console.log(err)
          reject(err)
      }
    )}
  )
}

/**
 * 
 * Fetch user by email id
 * @param email 
 */
fetchUserProfileByEmail(email:string){
    return new Promise((resolve, reject) => {
      this.db.list("/users", ref => ref.orderByChild('email').equalTo(email)).snapshotChanges().pipe(
        map(changes => 
         changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {}}))
        )
       ).subscribe(
        (res:any) => {
            resolve(res)
        },
        err => {
            console.log(err)
            reject(err)
        }
      )}
    )
  }


  fetchUserTemplates(key:string, cxObject:string){
        console.log("start of fetchUserTemplates with cxObject:", cxObject)
        return new Promise((resolve, reject) => {
          this.db.object('/users/' + key + '/templates/' + cxObject).valueChanges().subscribe
          (
            (res:any) => {
              //console.log(JSON.stringify(res))
              resolve(res)
            }
          ),
          err => { 
            console.log(err)
            reject(err)
          }
      })
  }

  /**
   * This method creates a template by copying it from global template list to the user node
   * @param payload 
   * @param key 
   * @param cxObject 
   */
  copyTemplate(payload, key:string){

    return new Promise((resolve, reject) => {
      this.db.object('/users/' + key + '/templates/').update(payload).then
      (
        (res:any) => {
          //console.log(JSON.stringify(res))
          resolve(res)
        }
      ),
      err => { 
        console.log("cannot:" + err)
        reject(err)
      }
  })

  }

/**
 * This method fetches user specific template actions
 * @param userId 
 */
  fetchUserTemplateActions(userId:string){
      return new Promise((resolve, reject) => {
        this.db.object("/users/" + userId + "/templateActions/").valueChanges().subscribe
        (
          (res:any) => {
            //console.log(JSON.stringify(res))
            resolve(res)
          }
        ),
        err => { 
          console.log(err)
          reject(err)
        }
    })
  }

  /**
   * This method fetches global actions
   * @returns 
   */
  fetchTemplateActions(){
      return new Promise((resolve, reject) => {
        this.db.object("/config/templateActions/").valueChanges().subscribe
        (
          (res:any) => {
            //console.log(JSON.stringify(res))
            resolve(res)
          }
        ),
        err => { 
          console.log(err)
          reject(err)
        }
    })
  }
  

  fetchGlobalTemplates(crmName:string){
      return new Promise((resolve, reject) => {
        this.db.object("/config/templates/" + crmName).valueChanges().subscribe
        (
          (res:any) => {
            //console.log(JSON.stringify(res))
            resolve(res)
          }
        ),
        err => { 
          console.log(err)
          reject(err)
        }
    })
  }

    /**
   * This method  creates a new enterprise account
   * @param payload 
   */
  createEnterprise(payload:any){
    return new Promise((resolve, reject) => {
      this.db.list("/enterprise/").push(payload).then
      (
        (res:any) => {
          //console.log(JSON.stringify(res))
          resolve(res)
        }
      ),
      err => { 
        console.log("cannot toogle:" + err)
        reject(err)
      }
  })
  }

}
