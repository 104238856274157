<div fxLayout="column" fxLayoutAlign="center"  style="padding:20px" style="background-color: white;">
  <div fxLayout="row" fxLayoutAlign="start ">
    <mat-form-field  style="padding-left:20px;padding-top:30px">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>
  </div>

  <div fxLayout="column" style="width:100%" #TABLE matSort>
    <table mat-table [dataSource]="dataSource">
        <!-- Company Column -->
        <ng-container matColumnDef="objName">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header>Object </th>
          <td mat-cell *matCellDef="let element"><div class="sg-link" [ngClass]="element.enabled === true ? '': 'sb-greyed-out'"> {{element.objName}} </div></td>
        </ng-container>
      
        <!-- Email Column -->
        <ng-container matColumnDef="wfName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Workflow Name </th>
          <td mat-cell *matCellDef="let element"> 
              <span [ngClass]="element.enabled === true ? '': 'sb-greyed-out'">{{element.wfName}}</span><br/>
              <div [ngClass]="element.enabled === true ? 'sub-text': 'sb-greyed-out'">{{element.wfDescription}}</div>   </td>
        </ng-container>

         <!-- Email Column -->
         <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="wfChanged(element)">
                <mat-icon *ngIf="element.enabled === true" color="primary">check_circle</mat-icon> 
                <mat-icon *ngIf="element.enabled === false" style="color:lightgray;">check_circle</mat-icon>
              </button>
            </td>
          </ng-container>     
      

         <!-- delete Column -->
         <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef  class="tbl-th"> Delete </th>
          <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="warn" *ngIf="element.removable === true" (click)="deleteWf(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>     


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
  </div> 

  <div fxLayout="row" fxFlex=100 fxLayoutAlign="end "  style="padding-right: 20px">
    <button mat-fab (click)="addWorkflow()">
        <mat-icon>add</mat-icon>
      </button>
</div>

</div>
   
  