/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 2 2022
 * 
 * Description: Backing code to create new workflows
 * 
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreService } from '../core.service';
import { MitDataService } from '../data.service';
import { AttributeDefModel } from '../model/attribute-def.model';
import { GlobalVariables } from '../model/global-variables.modal';
import { WorkflowModal } from '../model/workflow.modal';
import { OecService } from '../oec.service';


@Component({
  selector: 'app-create-workflow',
  templateUrl: './create-workflow.component.html',
  styleUrls: ['./create-workflow.component.css']
})
export class CreateWorkflowComponent implements OnInit {
  wfName = new FormControl('')
  description = new FormControl('')
  notiChannel = new FormControl('teams') //channel where notification will be sent
  channelId = new FormControl('') //only valid for slack. for teams auto detected based on membership
  tempCrmName  = GlobalVariables.ORACLE

  cxObject
  entKey
  objAttributes = []
  queryRows = []
  query

  constructor(public oecSvc: OecService,
              public core: CoreService,
              public dialogRef: MatDialogRef<CreateWorkflowComponent>,
              private dataSvc: MitDataService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.entKey = data.entKey
               }

  
  /**
   * Triggers on selecting an object from the workflow create dropdown
   * @param val 
   */             
  async cxObjectChanged(val){
    console.log("new value is:" + val)
    this.cxObject = val
    this.refreshObject()
  }


  /**
   * Adds a blank row to create a condition
   * @returns 
   */
  addBlankRow(){
    if(this.queryRows.length === 0)
      this.queryRows.push({"num": 1, "validated": false})
    else{
      //make sure other rows are filled before adding a new row
      for(let row of this.queryRows){
         if(row.field === undefined || row.oper === undefined || row.value === undefined){
           this.core.showMessage(GlobalVariables.ERROR, 'Invalid condition', 'All query conditions must be valid before adding new rows')
           return
         }
      }
      console.log("query lenght", this.queryRows.length)
      this.queryRows.push({"num": this.queryRows.length + 1, "validated": false})
    }
  }


  /**
   * when a new query condition is validated this event is emitted
   * @param value 
   */
  queryRowAdded(value){
    this.core.log("new row is:", value)
    //update the main query object

    this.queryRows[value.num - 1] = value

    this.core.log("query data is:", this.queryRows)
  }

  /**
   * when query component row is deleted this event is emitted
   * @param value 
   */
  queryRowDeleted(value){
    console.log("delete row with number", value)
    for(let row of this.queryRows){
      if(row.num === value -1 ){
        this.queryRows.splice(row.num, 1)
        this.core.log("selected query row deleted with row.num:", row.num)
      }else if(row.num > value -1 ){
        //adjust row numbers 
        row.num = row.num - 1
      }
    }
  }
  
  /**
   * To simplify workflows 
   * @param objectName 
   */
  transformWfObject(objectName:string){
    if(objectName === 'opportunities')
      return "opty"
    else if(objectName === 'leads')
      return "lead"
    else if(objectName === 'accounts')
      return "account"
    else if(objectName === 'contacts')
      return "contact"

    return ""
  }

  /**
   * validates the workflow and then creates it
   * @returns 
   */
  async validateAndSave(){
    if(this.wfName.value.trim() === ''){
      this.core.showMessage(GlobalVariables.ERROR, "Missing field", "Workflow name is required")
      return
    }

    if(this.description.value.trim() === ''){
      this.core.showMessage(GlobalVariables.ERROR, "Missing field", "Workflow description is required")
      return
    }


    if(this.notiChannel.value === 'slack' && this.channelId.value === ""){
      this.core.showMessage(GlobalVariables.ERROR, "Missing field", "Channel id is required for Slack")
      return
    }
    this.core.log("ent key:", this.entKey)

    if(this.queryRows.length === 0){
      this.core.showMessage(GlobalVariables.ERROR, "Missing conditions", "At least one workflow condition is required")
      return
    }

    for(let row of this.queryRows){
      if(row.field === undefined || row.oper === undefined || row.value === undefined){
        this.core.showMessage(GlobalVariables.ERROR, 'Invalid condition', 'All query conditions must be valid. Make sure to click tick mark at the end of each query condition')
        return
      }
    }

    /**
     * create the final query expression
     */
    const query = this.createQuery()

    this.core.log("created query is:", query)

    const workflow = new WorkflowModal('', this.wfName.value.trim(), this.description.value.trim(), true, true, this.transformWfObject(this.cxObject), query, this.notiChannel.value, this.channelId.value)
    workflow.crm = this.core.config.crmName
    console.log("channel id in workflow is:", workflow.channelId)
    await this.dataSvc.createWorkflow(this.entKey, workflow)
    this.dialogRef.close({"result": "ok"})
  }


/**
 * This method iterates over all the query rows and create expression
 * @returns 
 */
  createQuery(){

    let qExpr
    
    if(this.core.config.crmName === GlobalVariables.ORACLE && this.core.config.isAdaptive === true){
      qExpr = []
      for(let row of this.queryRows){
          let qRowQuery = this.createQueryRow(row.field, row.oper, row.value) 
          console.log("got the query:", qRowQuery)
          qExpr.push(qRowQuery)

          return JSON.stringify(qExpr)
      }
    }else if(this.core.config.crmName === GlobalVariables.SALESFORCE){
      qExpr = ""
      for(let row of this.queryRows){
        let qRowQuery = this.createQueryRow(row.field, row.oper, row.value) 
        console.log("got the query:", qRowQuery)
        if(qExpr.trim() === "")
          qExpr =  qRowQuery
        else
          qExpr = qExpr + " AND " + qRowQuery
      }

      return qExpr
    }else if(this.core.config.crmName === GlobalVariables.FRESHSALES){
      //for now support just one condition in freshsales
      qExpr = ""
      const row = this.queryRows[0]
      console.log("query row is:", row)
      qExpr = this.createQueryRow(row.field, row.oper, row.value) 

      /*for(let row of this.queryRows){
        let qRowQuery = this.createQueryRow(row.field, row.oper, row.value) 
        console.log("got the query:", qRowQuery)
        qExpr = qExpr + " AND " + qRowQuery
      }*/
    }
    
    return qExpr
  }


  /**
   * 
   * creates query experession
   * @param field 
   * @param oper 
   * @param value 
   * @returns 
   */
   createQueryRow(field:string, oper:string, value:string){
    let queryOp 

    if(this.core.config.crmName === GlobalVariables.ORACLE && this.core.config.isAdaptive === true){
        if(oper === "equals")
          queryOp = "$eq"
        else  if(oper === "notequals")
          queryOp = "$ne"
        else if(oper === "contains")
          queryOp = "$co"
        else if(oper === "greaterthan")
          queryOp = "$gt"
        else if(oper === "lessthan")
          queryOp = "$lt"
        else if(oper === "startsWith")
          queryOp = "$sw"
        else if(oper === "endsWith")
          queryOp = "$ew"
        else 
          queryOp = "$eq"

        return {
          "op": queryOp,
          "attribute" : field,
          "value" : value
        }
    }else if(this.core.config.crmName === GlobalVariables.SALESFORCE){
      if(oper === "equals")
      queryOp = "="
      else  if(oper === "notequals")
        queryOp = "!="
      else if(oper === "greaterthan")
        queryOp = ">"
      else if(oper === "lessthan")
        queryOp = "<"
      else if(oper === "startsWith")
        queryOp = "%"
      else if(oper === "endsWith")
        queryOp = "%"
      else 
        queryOp = "="

      return " " + field + " " + queryOp + " '" + value + "'"  //add single quotes around the values else it is an invalid query
    }else if(this.core.config.crmName === GlobalVariables.FRESHSALES){
      /**
       * as a short cut if value is 'today' assume it is of date type and handle that way. To generalize this the filed type need to be passed here
       */
      if(value === 'today'){
        //for today case no special treatment is needed.
        return " " + field + " " + oper + " '" + value + "'"
      }
      return " " + field + " " + oper + " '" + value + "'"
    }
  }

  cancel(){
    this.dialogRef.close()
  }

    /**
   * This will fetch the current template of the selected object and will get attributes from the object describe
   */
     async refreshObject(){
      //rest arrays
      this.objAttributes = []
  
      //get object describe
        let data:any
        if(this.tempCrmName === GlobalVariables.SALESFORCE){
          data = await (await this.oecSvc.doObjectDescribe(this.core.getObjectName(this.tempCrmName, this.cxObject))).toPromise()
          this.core.progressState = 'determinate'
  
          this.core.log("sfdc descrbie is", data.fields)
  
            for(let attr of data.fields){
                   const attrDef = new AttributeDefModel(attr.name, attr.label, attr.type, false, true, undefined)
                    this.objAttributes.push(attrDef)
              }
        }else if(this.tempCrmName === GlobalVariables.FRESHSALES){
          data = await (await this.oecSvc.doObjectDescribe(this.cxObject)).toPromise()
          this.core.progressState = 'determinate'
  
          this.core.log("freshsales descrbie is", data.fields)
          for(let attr of data.fields){
            const attrDef = new AttributeDefModel(attr.name, attr.label, attr.type, false, true, undefined)
             this.objAttributes.push(attrDef)
          }

        }
        else if(this.tempCrmName === GlobalVariables.ORACLE){
              //oracle
              if(this.core.config.isAdaptive === undefined || this.core.config.isAdaptive === false){
                data = await this.oecSvc.doDescribe(this.cxObject).toPromise()
                this.core.progressState = 'determinate'
        
                if(data.error != undefined){
                    /*setTimeout(() => this.dialog.open(MessageComponent, {
                      width: '400px',
                      height: '270px',
                      panelClass: 'dialog-container',
                      data: { title:"Error", message: "End point not initialized", iconType: "error",
                              reason: "Go to home and make sure the CRM connection exists and is valid", showReasonLabel:true}
                    }))*/
        
                    return
                }
        
                for(let attr of data.Resources[this.cxObject].attributes){
                        const attrDef = new AttributeDefModel(attr.name, attr.title, attr.type, false, true, attr.lov)
                        this.objAttributes.push(attrDef)
                  }
        
                return
              }else{
                console.log("adaptive describe")
  
                data = await (await this.oecSvc.doDescribeAdaptive(this.core.getObjectName(this.tempCrmName, this.cxObject))).toPromise()
                for(let attr of data.items){
                    if(attr.displayLabel !== ""){
                      let lov = undefined
                      let attrName = attr.displayAttributePath
                      if(attr.attributeType === 'Enum'){
                        lov = attr.links[0].href
                        attrName = attrName.substring(0, attrName.indexOf("_"))
                      }
  
                      const attrDef = new AttributeDefModel(attrName, attr.displayLabel, attr.attributeType, false, true, lov)
                      //console.log("pushed attrDef", attrDef)
                      this.objAttributes.push(attrDef)
                    }
                  }
  
                  //this.core.log("adaptive resp", this.objAttributes)
                return
              }
        }
  }

  ngOnInit(): void {
    if(this.core.config.crmName === GlobalVariables.ORACLE && this.core.config.isAdaptive === true)
      this.tempCrmName = 'oracle_adaptive'
    else
      this.tempCrmName = this.core.config.crmName

     this.objAttributes = []

     this.core.log("crm name is", this.tempCrmName)
  }

}
