/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Apr 24 2021
 * 
 * Description: service that interacts with REST services
 * 
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CoreService } from './core.service'
import { MitDataService } from './data.service';
import { GlobalVariables } from './model/global-variables.modal';
import { AngularFireFunctions } from '@angular/fire/functions';

const PROXY = 'https://cors-anywhere.herokuapp.com/'

@Injectable({
  providedIn: 'root'
})
export class OecService {

  constructor(private http: HttpClient,
              private core: CoreService,
              public dataSvc: MitDataService,
              public fns: AngularFireFunctions) { }

  
  
  /**
   * A dummy call to validate the OEC connection is valid
   * @param endPoint 
   * @param username 
   * @param password 
   */
  validateOpty(endPoint, username, password){
    let headers:HttpHeaders = new HttpHeaders({
      'Authorization': "Basic " + btoa(username + ":" + password)
    });
    return this.http.get(endPoint +'/crmRestApi/resources/latest/opportunities?onlyData=true&limit=1',{headers: headers})
  }


  /**
   * This method validates FreshDesk connection
   * @param payload 
   */
  validateFreshDeskConnection(payload:any){
    var httpHeaders =  new HttpHeaders()
    httpHeaders = httpHeaders.append('Authorization',"Basic " + btoa(payload.freshDeskApiKey + ":" + 'XXX')) //in FreshDesk the call is made in a way that username is actually api key and password is ignored
    return new Promise((resolve, reject) => {
      this.http.get(PROXY + payload.freshDeskUrl + '/api/v2/companies/autocomplete?name=moblize', {headers:httpHeaders} ).subscribe(
        (res:any) => {
          console.log("response status:" + res.status)        
          resolve(res)
        },
        err => {
            console.log("err status:" + err.status)
            reject(err)
        }
      )
    })

  }


  /**
   * This method validates the FrehsSales connection by making a dummy call to contact
   * @param payload 
   */
  validateFreshSalesConnection(payload:any){
    var httpHeaders =  new HttpHeaders()
    httpHeaders = httpHeaders.append('Authorization','Token token=' + payload.apiKey)
    return new Promise((resolve, reject) => {
      this.http.get(PROXY + payload.url + '/api/contacts/filters', {headers:httpHeaders} ).subscribe(
        (res:any) => {
          console.log("response status:" + res.status)        
          resolve(res)
        },
        err => {
            console.log("err status:" + err.status)
            reject(err)
        }
      )
    })

  }


  async fetchJwtToken(endpoint:string,username:string, password:string){

    // '/fscmRestApi/tokenrelay?redirect_uri=https://localhost:4200'
    const url  = endpoint + '/fscmRestApi/anticsrf'
      return await this.http.get(url).toPromise()

    

    // return await this.http.get("https://us-central1-mitbusinesscardscanner.cloudfunctions.net/jwtGenerate?url=" + endpoint + "&username=" + username + "&password=" + password).toPromise()
  }

  /**
   * For Oracle 
   */
  builtHttpHeader(){
    const config = this.core.config
    let headers;
    
    if(config.crmName === GlobalVariables.SALESFORCE){
      headers = new HttpHeaders({
        'Authorization': this.core.AUTH_STRING
      });

      return headers
    }
    
    headers = new HttpHeaders({
      'Authorization': this.core.AUTH_STRING,
      'REST-Framework-Version': '4'
    });

    return headers
  }

  builtAdaptiveHttpHeader(){
    let headers:HttpHeaders = new HttpHeaders({
      'Authorization': this.core.AUTH_STRING,
      'Content-Type': 'application/json',
      'Preference': 'transient'
    });

    return headers
  }

  getAdaptiveAccountCount(condition:any){
    let headers = this.builtAdaptiveHttpHeader()
    const payload = {
      "entity": "Account",
      "onlyData": true,
      "fields": [
        "OrganizationName",
        "PrimaryURL",
        "PrimaryContactName",
        "PartyUniqueName"
      ],
      "q": {
        "op": "$or",
        "criteria": condition
      }
    }
    return this.http.post(PROXY + this.core.config.endpoint +'/crmRestApi/searchResources/latest/custom-actions/queries',payload, {headers: headers}).toPromise()
  }



  getAccountCount(){
    const headers = this.builtHttpHeader()
    return this.http.get(PROXY + this.core.config.endpoint +'/crmRestApi/resources/latest/' + 'accounts' + '?fields=PartyId&onlyData=true&totalResults=true',{headers: headers}).toPromise()
  }

  getIncompleteAccountCount(condition:string){
    const headers = this.builtHttpHeader()
    return this.http.get(PROXY + this.core.config.endpoint +'/crmRestApi/resources/latest/' + 'accounts' + '?fields=PartyId,OrganizationName&onlyData=true&totalResults=true&q=EmployeesTotal is null OR EmployeesTotal = 0',{headers: headers}).toPromise()
  }


  /**
   * 
   * @param cxObject TODO MOVE IT TO SERVER SIDE LIKE FOR SALESFORCE ELSE CORS ISSUE WILL KEEP BREAKING IT
   * @returns 
   */
  async doDescribeAdaptive(cxObject:string){
    console.log("start of doDescribeAdaptive cxObject:", cxObject)
    let cxObjectAdap = cxObject
    if(cxObject === 'leads')
      cxObjectAdap = 'Lead'
    else if(cxObject === 'accounts')
      cxObjectAdap = 'Account'
    else if(cxObject === 'contacts')
      cxObjectAdap = 'Contact'
    else if(cxObject === 'opportunities')
      cxObjectAdap = 'Opportunity'
    
      var descFunction = this.fns.httpsCallable('fetchDescribe');
      const res = await descFunction({"url": this.core.config.endpoint, "auth": this.core.AUTH_STRING, "object": cxObjectAdap, "crm": GlobalVariables.ORACLE, "isAdaptive": true})
      //this.core.log("test return", res)
      return res  
  }

  /**
   * To avoid cors issues this is moved to server side.
   * @param cxObject 
   * @returns 
   */
  async doObjectDescribe(cxObject:string){
    this.core.log("start of doObjectDescribe cxObject", cxObject)
    const config = this.core.config
    let headers = this.builtHttpHeader()  

    this.core.log("header is", headers)
    if(config.crmName === GlobalVariables.SALESFORCE){
        console.log("removed cors")
        var descFunction = this.fns.httpsCallable('fetchDescribe');
        const res = await descFunction({"url": this.core.config.endpoint, "auth": this.core.AUTH_STRING, "object": cxObject, "crm": GlobalVariables.SALESFORCE, "userKey": this.core.user.userId})
        this.core.log("test return", res)
        return res
    }else if(config.crmName === GlobalVariables.FRESHSALES){
      const descFunction = this.fns.httpsCallable('fetchDescribe');
      let res
      
      /**
       * only supported tickets object for freshdesk
       */
      if(cxObject === 'ticket_fields')
        res = await descFunction({"url": this.core.config.freshDeskEndPoint, "auth": this.core.FRESHDESK_AUTH_STRING, "object": "ticket_fields", "crm": GlobalVariables.FRESHSALES})
      else
        res = await descFunction({"url": this.core.config.endpoint, "auth": this.core.config.apiKey, "object": this.core.getObjectName(config.crmName, cxObject), "crm": GlobalVariables.FRESHSALES, "email": this.core.user.email})

      this.core.log("test return", res)
      return res
    }
  }


  /**
   * does the describe to find all available attributes
   */
  doDescribe(cxObject:string){
    const config = this.core.config
    let headers = this.builtHttpHeader()  

    this.core.log("header is", headers)
    /**
     * THIS METHOD SHOULD GO AWAY AS THIS IS MOVED TO SERVER SIDE USING doObjectDescribe
     */
   if(config.crmName === GlobalVariables.FRESHSALES){
      //TO SIMPLIFY DO A DESCRIBE ONLY ON FRESHDESK AND IGNORE FRESHSALES
      return this.http.get(PROXY + this.core.config.endpoint + '/services/data/v45.0/sobjects/' + cxObject + '/describe',{headers: headers})  
    }

    return this.http.get(PROXY + this.core.config.endpoint +'/crmRestApi/resources/latest/' + cxObject + '/describe',{headers: headers})
  }

  /**
   * Oracle CRM supports either basic or jwt type so figure out and return correct type
   */
  async setAuthString(){
    console.log("start of setAuthString")
    const config = this.core.config
    this.core.log("config object is:", config)

    if(config.crmName === GlobalVariables.FRESHSALES){
      console.log("do nothing for now about freshsales. need to figure out what needs to happen here")
      //consider only for Freshdesk
      this.core.FRESHDESK_AUTH_STRING = "Basic " + btoa(config.freshDeskApiKey + ":" + 'XXX')
      return
    }else if(config.crmName === GlobalVariables.SALESFORCE || config.crmName === GlobalVariables.HUBSPOT){
     this.core.AUTH_STRING = "Bearer " + config.accessToken
     return
   }

    //for Oracle adaptive and non adaptie mode
    if(config.cxAuthType === undefined || config.cxAuthType === '' || config.cxAuthType === 'basic'){
      this.core.AUTH_STRING = "Basic " + btoa(config.username + ":" + config.password);
      return 
    }else{
        //check if last jwt refresh was beyond 4 hours
        const timeNow = (new Date()).getTime()
         
        let lastRefresh = config.jwtTokenLastRefresh
        if(config.jwtTokenLastRefresh === undefined){
          lastRefresh = 0
        }

        const timeDiff = timeNow - +lastRefresh
        if(timeDiff > 4*60*60*1000){
          console.log("jwt token would have expired so refresh it for data:" + "https://us-central1-mitbusinesscardscanner.cloudfunctions.net/jwtGenerate?url=" + config.endpoint + "&username=" + config.username + "&password=" + config.password)
          const rsp:any =  await this.http.get(PROXY + "https://us-central1-mitbusinesscardscanner.cloudfunctions.net/jwtGenerate?url=" + config.endpoint + "&username=" + config.username + "&password=" + config.password).toPromise()
          console.log("Refresh jwt on expiry is:", rsp.token )
          const jwtToken = rsp.token
          //persist it to DB too
          const payload = {"jwtToken" : jwtToken, "jwtTokenLastRefresh" : +timeNow}
          await this.dataSvc.saveCXConfig(payload, this.core.user.userId)  
          console.log("saved new jwt token to db")
          this.core.AUTH_STRING = 'Bearer ' +  jwtToken
        }else
          this.core.AUTH_STRING = 'Bearer ' + config.jwtToken
    }

    console.log("auth is:", this.core.AUTH_STRING)
  }

}
