/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jan 19 2020
 * 
 * Description: Handles login flow and security
 * OBSOLETE AND NO LONGER NEEDED. THIS IS HANDELED IN DASHBAORD.TS
 * 
 */

import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public core:CoreService) { }

  ngOnInit() {
  }

}
