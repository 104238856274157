/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jan 21 2020
 * 
 * Description: This dialog adds ability to add a new attribute in the response
 * 
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-attribute',
  templateUrl: './add-attribute.component.html',
  styleUrls: ['./add-attribute.component.scss']
})
export class AddAttributeComponent implements OnInit {
  title = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required]);
  shortField:boolean

  constructor(public dialogRef: MatDialogRef<AddAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.shortField = true  //if true then this attribute will show towards header rather in fact list
    this.title.setValue(data.title)
    this.name.setValue(data.name)
  }

      /**
  * Shows required field validations
  */
 getErrorMessage() {
  return this.title.hasError('required') ? 'You must enter a value' : '';
}

/**
 * this method will add the attribute to the available list
 */
addAttribute(){
  this.dialogRef.close({
    "title" : this.title.value,
    "name": this.name.value,
    "inFactList": this.shortField
  })
}


  ngOnInit() {
  }

}
