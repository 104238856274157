<div fxLayout="column" style="width:100%;height:100vh">
    <mat-toolbar color="primary">
    <mat-toolbar-row>
      <img src="/assets/images/logo1.png" width=150>
      <span style="width:100%;text-align:center">FlashCX.ai - Your CRM on Slack, Teams & Webex</span>
    </mat-toolbar-row>
</mat-toolbar>

<div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center" style="height: 100%;background-image: url('/assets/images/bg.png');background-position:center;background-size:cover;background-repeat: no-repeat;" >
    <mat-card>
            <mat-card-title>Login</mat-card-title>
            <mat-card-subtitle>Please login with your slack id to continue</mat-card-subtitle>
            <mat-card-content>
                 <a href="https://slack.com/oauth/authorize?scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=386994802261.387757872486&redirect_uri=https%3A%2F%2Fus-central1-salesbot-slack.cloudfunctions.net%2FoauthSignin"><img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" /></a>
            </mat-card-content>
    </mat-card>
</div>
<!--a href="#" routerLink="/dashboard">dashboard</a-->
</div>