  /**
   * Author: Neeti
   * Project: Before You Buy
   * Copyright: Moblize.IT LLC | All rights reserved
   * Last Modified: Mar 3 2021
   * 
   * Description: Create enterprise
   * 
   */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from '../core.service';
import { MitDataService } from '../data.service';
import { FirebaseServiceService } from '../firebase-service.service';
import { MessageComponent } from '../message/message.component';
import { GlobalVariables } from '../model/global-variables.modal';

@Component({
  selector: 'app-create-enterprise',
  templateUrl: './create-enterprise.component.html',
  styleUrls: ['./create-enterprise.component.css']
})
export class CreateEnterpriseComponent implements OnInit {

  companyName = new FormControl('');
  companyLogo:string
  adminName = new FormControl('');
  adminEmail = new FormControl('');
  userRole = new FormControl('');
  domain = new FormControl('');

  options:any[]

  constructor(private  dataSvc:MitDataService,
            private _snackBar: MatSnackBar,
            private core:CoreService,
            private fbSvc: FirebaseServiceService) { }


  async raiseMessage(msg:string){
    this._snackBar.openFromComponent(MessageComponent, {
      duration: 3000,
      data: msg,
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  selectionChange(option){
    console.log("selecte value is:" + JSON.stringify(option))
    this.companyLogo = option.logo 
    this.companyName.setValue(option.name)
  }

  /**
   * search a company 
   * @param event 
   */
  async searchCompany(event:any){
    console.log("event is:" + event.target.value)
    if(this.companyName.value === '' || this.companyName.value.length < 3)
      return

    const resp = await this.fbSvc.findCompanyByName('name', this.companyName.value)
    console.log("company search response is:" + JSON.stringify(resp))

    this.options = []
    for(let key of Object.keys(resp)){
      if(resp[key].displayName === undefined || resp[key].name === undefined || resp[key].logo === undefined){
        alert('missing data for key:' + resp[key].key)
        break;
      }
      this.options.push(
        {
          "name": resp[key].displayName,
          "value": resp[key].name,
          "logo": resp[key].logo
        }
      )
    }
  }

  async save(){

    //validate data first

    if(this.adminEmail.value === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Admin email is required')
      return false;
    }

    if(this.companyName.value === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Company name is required')
      return
    }

    if(this.companyLogo === undefined || this.companyLogo === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Company logo URL is required')
      return
    }

    if(this.adminName.value === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'Admin name is required')
      return
    }

    if(this.domain.value === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'URL is required')
      return
    }

    if(this.userRole.value === ''){
      this.core.showMessage(GlobalVariables.ERROR, 'Cannot validate', 'User role is required')
      return
    }


    const payload = {
      "_adminEmail": this.adminEmail.value,
      "_adminName": this.adminName.value,
      "_company": this.companyName.value,
      "_domain": this.domain.value,
      "_logo": this.companyLogo,
      "_role": this.userRole.value,
      "_joinDate": (new Date()).getTime(),
      "_licenseType": GlobalVariables.TRIAL,
      "notifications": false,
      "_teamsConversationsLink": false
    }

    console.log("payload is::" + JSON.stringify(payload))
    await this.dataSvc.createEnterprise(payload)

    this.core.showMessage(GlobalVariables.SUCCESS, 'Success!', 'New Enterprise Account Created')

     //clear the form
     this.companyName.setValue('')
     this.adminEmail.setValue('')
     this.adminName.setValue('')
     this.domain.setValue('')
     this.companyLogo = ''

    //alert('Updated')
  }

  ngOnInit() {
  }

}
