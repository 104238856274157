/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Sep 13 2020
 * 
 * Description: This is the page header
 * 
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';

import { CoreService } from '../core.service'
import { UserModel } from '../model/user.model';
import { GlobalVariables } from '../model/global-variables.modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public core:CoreService,
               private router:Router,
               public db: AngularFireDatabase) { }



  /**
   * This method loads billing and plan module
   * DISABLED for now
   */
  openBilling(){
    //this.router.navigate(['billing'])
  }



  ngOnInit() {

    if(this.core.user === undefined)
      this.core.user = new UserModel('','','','', GlobalVariables.USER, '')
  }

}
