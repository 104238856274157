<mat-toolbar color="primary">
    <mat-toolbar-row>
      <img src="/assets/images/logo1.png" width=150>
      <span style="width:100%;text-align:center" *ngIf="core.loggedIn === false">FlashCX.ai - Your CRM on Slack, Teams & Webex</span>
      <span style="width:100%;text-align:center" *ngIf="core.loggedIn === true">{{core.currentPageLabel}}</span>

      <span style="flex: 1 1 auto;"></span>
      <div fxLayout="row" *ngIf="core.user !== undefined && core.loggedIn === true">
        <div fxLayout="row" fxLayoutAlign=" center">
          <img [src]="core.user.avatar" alt="Avatar" class="avatar">
        </div>
        <div fxLayout="column" style="padding-left:5px">
          <div style="height: 20px;">{{core.user.name}}</div>
          <div style="font-size: small">{{core.user.email}}</div>
        </div>
      </div>
      <div fxLayout="row"  *ngIf="core.user !== undefined && core.loggedIn === true">
        <!--button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>arrow_drop_down</mat-icon>
        </button-->
        <mat-menu #appMenu="matMenu">
            <!--button mat-menu-item (click)="openBilling()">Billing</button -->
        </mat-menu>
      </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar
          color="primary"
          [mode]=core.progressState
          value = 0
          bufferValue = 75>
</mat-progress-bar>