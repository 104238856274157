<h2 mat-dialog-title>Create Enterprise Account</h2>
<mat-dialog-content>
    <mat-card style="width: 500px">
        <mat-card-content>
          <div fxLayout="column" style="width:100%">
            <div fxLayout="row" fxFlex="10">
              <div fxLayout="row"><img [src]="companyLogo" width="100" height="100"></div>
              <div style="padding-left: 10px;width: 100%;">
                <mat-form-field style="width: 100%;">
                  <input matInput placeholder="Company Name" [formControl]="companyName" (keydown)="searchCompany($event)" [matAutocomplete]="auto">
                  <mat-autocomplete #auto>
                   <mat-option *ngFor="let option of options" [value]="option.value" (onSelectionChange)="selectionChange(option)">
                     {{ option.name }}
                   </mat-option>
                 </mat-autocomplete>
               </mat-form-field>
              </div>
            </div>
            <mat-form-field>
              <input matInput placeholder="Admin Name" [formControl]="adminName">
           </mat-form-field>
           <mat-form-field>
            <input matInput type='email' placeholder="Admin Email" [formControl]="adminEmail">
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Domain e.g moblize.it" [formControl]="domain">
         </mat-form-field>
            <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select [formControl]="userRole">
                  <mat-option  value="enterpriseAdmin">
                    Enterprise Admin
                  </mat-option>
                  <mat-option  value="superuser">
                      Super User
                  </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
        </mat-card-content>
      </mat-card>
</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-evenly">
          <button mat-raised-button [mat-dialog-close]="true" color="accent">Close</button>
  
    <button mat-raised-button color="accent" (click)="save()">Save</button>
  </div>
  </mat-dialog-actions>