/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Jun 28 2020
 * 
 * Description: Doughnut chart component
 * 
 */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Label, BaseChartDirective } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-stat-chart',
  templateUrl: './stat-chart.component.html',
  styleUrls: ['./stat-chart.component.scss']
})
export class StatChartComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true })
  chartElem: BaseChartDirective;


  public pieChartLabels: Label[] = ['Healthy Accounts',  ''];
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartData: number[] = [0, 0];
  public centerText;


  @Input() data:any

  public pieChartColors = [
    {
      backgroundColor: [ 'rgba(11,255,199,1)' , 'rgba(244,120,120,1)'],
    },
  ];
  
  public pieChartOptions: ChartOptions = {
    responsive: true,
    cutoutPercentage: 80,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
    animation: {
      onComplete: function(animation) {
        this.modifyCenterText();
      }.bind(this)
    }
  };

  modifyCenterText() {
    console.log("modifyCenterText started:::::::" + this.centerText)
    const chart = this.chartElem.chart;
    const ctx = chart.ctx;
    const txt = "Center Text";

    //Get options from the center object in options
    const sidePadding = 60;
    const sidePaddingCalculated =
      (sidePadding / 100) * (chart["innerRadius"] * 2);
    console.log(chart);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = chart["innerRadius"] * 2 - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = chart["innerRadius"] * 2;

    // Pick a new font size so it will not be larger than the height of label.
    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    ctx.font = fontSizeToUse + "px Arial";
    ctx.fillStyle = "blue";

    // Draw text in center
    ctx.fillText(this.centerText, centerX, centerY);
  }


  constructor() { 
  }

  ngOnInit(): void {
    console.log("inside pie:", this.data)
    this.pieChartData = [this.data.total, this.data.pending]
    this.pieChartColors = [
      {
        backgroundColor: [ this.data.color , 'rgba(223,223,223,1)'],
      },
    ];

    this.pieChartLabels = this.data.labels
    this.centerText = this.data.centerText
  }

}
