/**
 * Product: FlashCX.ai
 * Author: Vikram
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar 2 2022
 * 
 * Description: Backing code to create new workflows query row
 * 
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CoreService } from '../core.service';
import { GlobalVariables } from '../model/global-variables.modal';
import { OecService } from '../oec.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AttributeDefModel } from '../model/attribute-def.model';

@Component({
  selector: 'app-wf-query-row',
  templateUrl: './wf-query-row.component.html',
  styleUrls: ['./wf-query-row.component.css']
})
export class WfQueryRowComponent implements OnInit {

  cxObject
  wfCondField = new FormControl('')
  wfCondValue = new FormControl('')
  wfCondOper = new FormControl('equals')
  filteredOptions: Observable<any>;
  @Input() objAttributes:[]
  @Input() num:any
  @Input() qrow:any
  @Output() qRowEvent = new EventEmitter<any>();
  @Output() qRowDeleteEvent = new EventEmitter<any>();

  currentAttr:AttributeDefModel //this is the attribute selected to set condition. helps in setting correct list of operators and value type

  constructor(public core:CoreService,
              public oecSvc: OecService) {
                this.filteredOptions = this.wfCondField.valueChanges
                .pipe(
                  startWith(''),
                  map(value => this._filter(value))
                );
    }

  /**
   * if valid return the row to the parent component
   */
   validateRow(){
    this.core.log("condition is:", this.wfCondField.value + ":" + this.wfCondOper.value + ":" + this.wfCondValue.value)

    if(this.wfCondField.value === '' || this.wfCondOper.value === '' || this.wfCondValue.value === '' ){
      this.core.showMessage(GlobalVariables.ERROR, 'Missing Values', 'All the fields of the query condition are required')
      return
    }

    this.qrow.validated = true

    const qObj = {"num": this.num,  "field" : this.wfCondField.value, "oper" : this.wfCondOper.value, "value" : this.wfCondValue.value, "validated": true}
    this.qRowEvent.emit(qObj)

    //const query = this.createQuery(this.wfCondField.value, this.wfCondOper.value, this.wfCondValue.value)
    //this.core.log("query criteria is:", query)
  }

  deleteRow(){
    this.qRowDeleteEvent.emit(this.num)
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.objAttributes.filter( (option:any) => option.name.toLowerCase().includes(filterValue));
  }

  async cxAttrChanged(val){
    console.log("new value is:" + val)
    for(let attr of this.objAttributes){
      const atr:any = attr
      if(atr.name === val){
        console.log(attr)
        this.currentAttr = attr
      }
    }
  }



  ngOnInit(): void {
   this.core.log("in componentne attrs are:", this.objAttributes)
   this.core.log("row number is:", this.num)
   if(this.qrow !== undefined){

    if(this.qrow.field !== undefined)
      this.wfCondField.setValue(this.qrow.field)

    if(this.qrow.oper !== undefined)
      this.wfCondOper.setValue(this.qrow.oper)

    if(this.qrow.value !== undefined)
        this.wfCondValue.setValue(this.qrow.value)
   }
  }

}
