 /**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: Mar16 2021
 * 
 * Description: Global constant file
 * 
 */
export const GlobalVariables = Object.freeze({
    ERROR: "error",
    FRESHSALES: "freshsales",
    HUBSPOT: "hubspot",
    ORACLE: "oracle",
    QUESTION: "question",
    SALESFORCE: "salesforce",
    SUCCESS: "success",
    TOKEN: "token",
    TRIAL: "trial",
    USER: "user"
});
