<h2 mat-dialog-title>Manage Enterprise License</h2>
<mat-dialog-content>
    <mat-card style="width: 500px">
        <mat-card-content>
          <div fxLayout="column" style="width:100%">

            <mat-form-field style="width: 100%;padding-top:20px" appearance="outline">
                <mat-label>License Type</mat-label>
                <mat-select [formControl]="licenseType">
                  <mat-option  value="trial">
                    Trial
                  </mat-option>
                  <mat-option  value="Teams - Starter">
                    Teams - Starter
                </mat-option>
                <mat-option  value="Teams - Optimum">
                    Teams - Optimum
                </mat-option>
                <mat-option  value="Teams - Premium">
                    Teams - Premium
                </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>License start date</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="License start date" [formControl]="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>


            <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>License end date</mat-label>
                <input matInput [matDatepicker]="picker1" placeholder="License end date" [formControl]="endDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>Users</mat-label>
                <input matInput  placeholder="users count"  [formControl]="users"  type="number" required>
            </mat-form-field>

            </div>
        </mat-card-content>
      </mat-card>
</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-evenly">
          <button mat-raised-button [mat-dialog-close]="true" color="accent">Close</button>
  
    <button mat-raised-button color="accent" (click)="updateLicense()">Save</button>
  </div>
  </mat-dialog-actions>