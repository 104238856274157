/**
 * Author: Neeti
 * Copyright: Moblize.IT LLC | All rights reserved
 * Last Modified: MAr 2 2022
 * 
 * Description: Workflow modal component
 * 
 */
export class WorkflowModal {
    private _key:string
    private _name:string
    private _description:string
    private _enabled:boolean
    private _removable:boolean
    private _object:string
    private _crm:string
    private _query:string
    private _channel:string
    private _channelId:string //for slack only

    constructor(key:string, name:string, description:string, enabled:boolean, removable:boolean, object:string, query:string, channel:string, channelId:string){
        this._key = key
        this._name = name
        this._description = description
        this._enabled = enabled
        this._removable = removable
        this._object = object
        this._query = query
        this._channel = channel
        this._channelId = channelId
    }

    get key():string{
        return this._key
    }

    get channelId():string{
        return this._channelId
    }

    get name():string{
        return this._name
    }

    get description():string{
        return this._description
    }

    get enabled():boolean{
        return this._enabled
    }

    set enabled(val:boolean){
        this._enabled = val
    }

    get removable():boolean{
        return this._removable
    }

    set crm(val:string){
        this._crm = val
    }

    set query(val:string){
        this._query = val
    }

    get query():string{
        return this._query
    }
}