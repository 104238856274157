import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { BillingComponent } from './billing/billing.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { LoginComponent } from './login/login.component'
import { CustomizeComponent } from './customize/customize.component'


const appRoutes: Routes = [ 
    {
        path: 'customize',
        component: CustomizeComponent
    },
    {
        path: '',
        component: DashboardComponent
    }, 
    {
        path: 'billing',
        component: BillingComponent
    }/*,
    {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
    }*/
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
